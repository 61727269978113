<template>
    <a-modal
        :visible="visible"
        width="500px"
        :footer="null"
        @cancel="onClose"
        :centered="true"
    >
        <BhLoading :show="loading" />
        <div class="mb-2">
            <h5 class="mb-4" v-text="'Bulk Update Status'" />

            <p class="mb-2" style="color: #40454c; font-size: 14px">
                Status<span style="color: var(--danger)"> *</span>
            </p>
            <a-select
				@change="changeStatus"
				v-model="selectedStatus"
				class="w-full"
			>
				<a-select-option
					v-for="stat in statusOptions"
					:key="stat.value"
					:value="stat.value"
					>{{ stat.label }}</a-select-option
				>
			</a-select>
        </div>
        <div class="dF jE mt-5">
            <a-button class="cancel-button" @click="onClose">CANCEL</a-button>
            <a-button class="ml-3" type="primary" @click="onOk"
                >UPDATE</a-button
            >
        </div>
    </a-modal>
</template>

<script>
import BhLoading from "bh-mod/components/common/Loading";

export default {
    props: [
        "visible",
        "selectedLots",
        "lots",
        "selectedUnits",
        "units",
        "addOn",
    ],
    components: { BhLoading },
    data: () => ({
		selectedStatus: "available",
        statusOptions: [
			{ value: "available", label: "Available" },
			{ value: "notreleased", label: "Not Released" }
		],
        loading: false,
    }),
    watch: {},
    computed: {
        siteplan() {
            return this.$store.state.inventory.siteplan.id;
        },
        storeTags() {
            return this.$store.state.inventory.tags || {};
        },
        instance() {
            return this.$store.state.instance;
        },
    },
    methods: {
        async onOk() {
            if (!this.selectedStatus) return this.$message.error("Please select a status to apply.");

            this.loading = true;
            if (this.instance.productType === "lowrise") {
                const lotsToUpdate = [];
                let updateError = false;
                this.lots.forEach(async (x) => {
                    if (this.selectedLots.includes(x.id)) {
                        if (x.transactionId && x.status !== "available" && x.status !== 'notreleased') {
                            updateError = true;
                        } else {
                            lotsToUpdate.push(x.id);
                        }
                    }
                });
                if (updateError) {
                    this.$message.error(
                        "You can only update the status of available or not released lots."
                    );
                }
                if (!lotsToUpdate.length) {
                    this.loading = false;
                    this.onClose();
                    return;
                }
                let sendObj = {
                    lots: lotsToUpdate,
                    status: this.selectedStatus,
                };
                this.$api
                    .put(`/lots/:instance/${this.siteplan}/bulkStatus`, sendObj)
                    .then(() => {
                        this.$emit("applyBulkStatus", this.selectedStatus);
                        this.onClose();
                    })
                    .catch((err) => {
                        this.$message.error(
                            "An error occurred. Please try again"
                        );
                        console.error(err);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                const unitsToUpdate = [];
                let updateError = false;
                this.units.forEach(async (x) => {
                    if (this.selectedUnits.includes(x.id)) {
                        if (x.transactionId && x.salesStatus !== "available" && x.salesStatus !== 'notreleased') {
                            updateError = true;
                        } else {
                            unitsToUpdate.push(x.id);
                        }
                    }
                });
                if (updateError) {
                    this.$message.error(
                        "You can only update the status of available or not released units."
                    );
                }
                if (!unitsToUpdate.length) {
                    this.loading = false;
                    this.onClose();
                    return;
                }
                let sendObj = {
                    units: unitsToUpdate,
                    status: this.selectedStatus
                };
                this.$api
                    .put(`/units/:instance/bulkStatus`, sendObj)
                    .then(({ data }) => {
                        this.$emit("applyBulkStatus", this.selectedStatus);
                        this.onClose();
                    })
                    .catch((err) => {
                        this.$message.error(
                            "An error occurred. Please try again"
                        );
                        console.error(err);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        onClose() {
            this.$emit("closeBulkStatus");
            this.selectedStatus = "available";
        },
    },
};
</script>

<style>
</style>
