<template>
    <div class="stack-card py-1 px-2 h-full dF aC jSB" :style="`background-color: ${salesStatusColorCode[unit.salesStatus]}; color: ${unit.salesStatus == 'hold' ? '#000' : '#FFF'}; ${displayType == 'sqft' ? `min-width: ${calculateWidth}px; max-width: ${calculateWidth}px;` : 'min-width: 180px; max-width: 180px;'}`">
		<div class="f1" @click="showUnit(unit.id)" style="cursor: pointer;">
			<div style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr)); gap: 0.5em;">
				<div style="font-weight: bold;">{{ unit.unitNumber }}</div>
				<div style="font-weight: bold; min-width: 65px; max-width: 65px; text-overflow: ellipsis; overflow: hidden;">{{ salesStatus[unit.salesStatus] }}</div>
			</div>
			<div class="mt-2" style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr)); gap: 0.5em;">
				<div class="dF aC">
					<i class="fa fa-bed"></i>
					<div class="ml-2">{{ unit.packages.length && unit.packages[0].beds || 0 }}</div>
				</div>
				<div class="dF aC">
					<i class="fa fa-bath"></i>
					<div class="ml-2">{{ unit.packages.length && unit.packages[0].baths || 0 }}</div>
				</div>
			</div>
		</div>
		<MoreActions
			v-if="$userType === 'agent' && ['available', 'allocated'].includes(unit.salesStatus)"
			trigger="click"
			:items="showActions(unit)"
		/>
		<a-tooltip
			placement="topRight"
			title="Only Owner allowed to perform any action on unit which is not available."
			v-else-if="$userType === 'agent'"
		>
			<a-button
				:disabled="true"
				shape="circle"
				icon="lock"
			/>
		</a-tooltip>
		<a-tooltip
			placement="topRight"
			:title="
				unit.transactionId && unit.salesStatus === 'sold'
					? 'This Unit has been SOLD. A mutual release is required to Void the sale.'
					: 'This Unit is locked as its currently in a Transaction. Please check the Transaction App'
			"
			v-else-if="
				unit.transactionId &&
				unit.salesStatus !== 'available'
			"
		>
			<a-button
				:disabled="true"
				shape="circle"
				icon="lock"
			/>
		</a-tooltip>
		<MoreActions
			v-else
			trigger="click"
			:items="showActions(unit)"
		/>
    </div>
</template>

<script>
import MoreActions from "bh-mod/components/common/MoreActions";
export default {
    components: { MoreActions },
    props: ["id", "unit", "displayType"],
	data() {
		return {
			salesStatus: {
				available: "Available",
				sold: "Sold",
				hold: "Hold",
				conditional: "Sold Conditional",
				inventory: "Standing Inventory",
				notreleased: "Not Released",
				allocated: "Allocated",
				approved_for_reservation: "Approved",
				reserved: "Reserved"
			},
			salesStatusColorCode: {
				available: "#1EC48C",
				sold: "#FD6C83",
				hold: "#FECE5B",
				conditional: "#9693E8",
				notreleased: "#3F3356",
				allocated: "var(--primary)",
				approved_for_reservation: "#134F5C",
				reserved: "#419E00"
			}
		}
	},

    computed: {
		minSqft(){
			let highRiseOptions = this.$store.getters.highRiseOptions;
			let { minSqft } = highRiseOptions;
			return minSqft;
		},
		maxSqft(){
			let highRiseOptions = this.$store.getters.highRiseOptions;
			let { maxSqft } = highRiseOptions;
			return maxSqft;
		},
		calculateWidth(){
			let minWidth = 130;
			let maxWidth = 400;
			if (this.unit && this.unit.packages && this.unit.packages[0].sqft){
				let sqft = this.unit.packages[0].sqft;
				if (sqft > this.maxSqft){
					return maxWidth;
				} else if (sqft < this.minSqft){
					return minWidth;
				} else {
					let width = (sqft - this.minSqft) / (this.maxSqft - this.minSqft);
					width = width * (maxWidth - minWidth);
					width = width + minWidth;
					return width;
				}
			} else {
				return 130;
			}
		},
        availableUnits() {
            return this.$store.state.inventory.availableUnits || {};
        },
        storeGradings() {
            return this.$store.state.inventory.gradings;
        },
        storePremiums() {
            return this.$store.state.inventory.premiums;
        },
        storeLots() {
            return this.$store.state.inventory.lots;
        },
        condoPremiums() {
            return this.$store.state.inventory.condoPremiums;
        },
        // unit() {
        //     const selectedUnit = this.$store.state.inventory.units[this.id] || {};

		// 	if(selectedUnit.tags && selectedUnit.tags.length && this.tags.length){
		// 		selectedUnit.tags = selectedUnit.tags.filter(id => {
		// 			return this.tags.find(t => t.value === id)
		// 		})
		// 	}

		// 	return selectedUnit;
        // },
        units() {
            let units = {};
            Object.values(this.$store.state.inventory.units).forEach((unit) => {
                if (unit.buildingType == "condounits") {
                    units[unit.id] = unit;
                }
            });
            return units;
        },
        storeTags() {
            return this.$store.state.inventory.tags || {};
        },
        tags() {
            let list = [];

            if (Object.keys(this.storeTags).length) {
                let tags = Object.values(this.storeTags).filter(
                    (x) => x && x.name && x.id && x.type === "models"
                );

                tags.forEach((tag) => {
                    if (tag && tag.id && tag.name) {
                        let obj = {};
                        obj.value = tag.id;
                        obj.label = tag.name;
                        list.push(obj);
                    }
                });
            }
            return list;
        },

		appSettings() {
			let app = this.$store.state.inventory.allSettings.app;
			if (!app) app = { options: {} };
			return app.options;
		},

		agentCommissionStructure() {
			return this.appSettings.agentCommissionStructure || { commissionType: 'percentage', commissionAmount: null };
		},
    },
    methods: {
        checkPremiums() {
            let containsPremiums = false;
			const premiums = this.unit.packages && this.unit.packages[0] && this.unit.packages[0].other && this.unit.packages[0].other.premiums || [];
            Object.values(premiums).forEach(
                (prem) => {
                    if (prem && prem.active) {
                        containsPremiums = true;
                        return;
                    }
                }
            );
            return containsPremiums;
        },
        async showUnit(id) {
			console.log('Showing Unit...', this.unit)
            this.$store.commit("OPEN_UNIT_DETAILS", this.unit);
        },
        showActions(unit) {
            let { salesStatus } = unit;
			if(this.$userType === 'agent'){
				return [{
                    label: "Fill Up Worksheet",
                    value: "createWorksheet",
                    val: "worksheet",
                    action: () => this.$emit("createWorksheet"),
                    if: salesStatus === "available",
                    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>',
                }];
			}
            return [
                {
                    label: "Update Status",
                    value: "updateStatus",
                    val: "status",
                    action: () => this.$emit("updateStatus"),
                    if: !salesStatus.includes("sold"),
                    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg>',
                },
                {
                    label: "Make a Transaction",
                    value: "makeTransaction",
                    val: "transaction",
                    action: () => this.$emit("makeTransaction"),
                    if: !salesStatus.includes("sold"),
                    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>',
                },
                {
                    label: "Unsell Lot",
                    value: "unsell",
                    action: () => this.$emit("unSell"),
                    if: salesStatus.includes("sold") && this.$p >= 40,
					icon: '<svg xmlns="http://www.w3.org/2000/svg" width="14.558" height="14.094" viewBox="0 0 14.558 14.094"><path id="Icon_material-money-off" data-name="Icon material-money-off" class="cls-1" d="M13.827,7.554c1.632,0,2.237.666,2.292,1.644h2.026A3.294,3.294,0,0,0,15.2,6.215V4.5h-2.75V6.191a6.054,6.054,0,0,0-1.357.423l1.348,1.151A4.143,4.143,0,0,1,13.827,7.554ZM7.254,5.33,6.09,6.324,9.244,9.018c0,1.629,1.43,2.513,3.584,3.061l3.218,2.748a2.758,2.758,0,0,1-2.218.713c-1.888,0-2.631-.72-2.732-1.644H9.079c.11,1.715,1.613,2.678,3.374,3v1.7H15.2V16.91a4.973,4.973,0,0,0,2.246-.877l2.035,1.738,1.164-.994Z" transform="translate(-6.09 -4.5)" /></svg>'
                },
            ].filter((x) => x.if);
        },
        getUnitName(unit) {
            let id = unit.id ? unit.id : unit;
            let result = "N/A";
            unit = this.units[id];
            if (unit) result = unit.name;
            if (
                unit &&
                unit.hasOwnProperty("unitGroup") &&
                unit.unitGroup &&
                unit.unitGroup.name
            )
                result = `${unit.unitGroup.name} - ${unit.name}`;
            return result;
        },
        getPublishStatus(id) {
            let status = "Draft";
            if (
                this.storeLots[id] &&
                this.storeLots[id].publishStatus == "published"
            ) {
                status = "Released";
            } else {
                status = "Draft";
            }
            return status;
        },
        getModelType(id) {
            let types = [];
            if (
                this.storeLots[id] &&
                this.storeLots[id].units &&
                this.storeLots[id].units.length
            ) {
                this.storeLots[id].units.forEach((unit) => {
                    let id = "";
                    if (typeof unit == "string") id = unit;
                    else if (typeof unit == "object") id = unit.id;

                    if (
                        this.units[id] &&
                        !types.includes(this.units[id].unitGroup.type)
                    ) {
                        types.push(this.units[id].unitGroup.type);
                    }
                });
                if (types.length > 1) {
                    types = "Various";
                } else if (types.length == 1) {
                    types = types[0];
                }
            } else {
                return (types = "N/A");
            }
            return types;
        },
        getFrontage(id) {
            let frontage = [];
            if (!this.storeLots[id].units.length) {
                return "N/A";
            } else {
                this.storeLots[id].units.forEach((unit) => {
                    let id = "";
                    if (typeof unit == "string") id = unit;
                    else if (typeof unit == "object") id = unit.id;
                    if (
                        this.units[id] &&
                        !frontage.includes(this.units[id].unitGroup.size)
                    ) {
                        frontage.push(this.units[id].unitGroup.size);
                    }
                });
                if (frontage.length == 1) {
                    return frontage[0];
                } else if (frontage.length > 1) {
                    let min = Math.min(...frontage);
                    let max = Math.max(...frontage);
                    return `${min}-${max}`;
                }
            }
        },
        getUnits(id) {
            let units = [];
            if (
                this.storeLots[id] &&
                this.storeLots[id].units &&
                this.storeLots[id].units.length
            ) {
                this.storeLots[id].units.forEach((unit) => {
                    if (this.units[unit]) {
                        units.push(
                            `${this.units[unit].unitGroup.name} - ${this.units[unit].name}`
                        );
                    }
                });
            }
            return units;
        },
        getPremium(id) {
            let price = 0;
            let premiums =
                (this.storeLots[id].other &&
                    this.storeLots[id].other.premiums) ||
                {};
            premiums = Object.values(premiums).filter((x) => x.active);

            if (premiums.length == 0) return 0;
            else {
                premiums.forEach((x) => {
                    price = price + x.price;
                });
                return price;
            }
        },
        getPremiums(id) {
            let premiums =
                (this.storeLots[id].other &&
                    this.storeLots[id].other.premiums) ||
                {};
            premiums = Object.values(premiums).filter((x) => x.active);

            if (premiums.length == 0) return "";
            return premiums.map((x) => {
                return `${x.name} - $${x.price.toLocaleString("us-en")}`;
            });
        },
        getGradings(id) {
            let gradings =
                (this.storeLots[id].other &&
                    this.storeLots[id].other.gradings) ||
                {};
            gradings = Object.values(gradings).filter((x) => x.active);

            if (gradings.length == 0) return "";
            return gradings.map((x) => {
                return `${x.name} - $${x.price.toLocaleString("us-en")}`;
            });
        },
        getCondition(id) {
            let conditions = {
                none: "None",
                sideRearUpgrade: "Side and Rear Upgrade",
                wob: "Walk-out Basement",
                rearUpgrade: "Rear Upgrade",
                other: "Other",
                cornerUpgrade: "Corner Upgrade",
            };
            let conds = [];
            let lot = this.storeLots[id];
            let other = null;
            if (!lot.condition) lot.condition = [];

            if (typeof lot.condition == "string") {
                return [conditions[lot.condition]];
            } else {
                lot.condition.forEach((x) => {
                    if (x === "other" && lot.notes && lot.notes.length) {
                        other = `Other: ${lot.notes}`;
                    } else if (x != "none") {
                        conds.push(conditions[x]);
                    }
                });

                if (other) conds.push(other);
                return conds;
            }
        },
    },
};
</script>

<style lang="scss">
.unsell-button.ant-btn {
    border: 1px solid var(--orange);
    color: var(--orange);
    svg {
        fill: var(--orange);
    }
}
.unsell-button.ant-btn:hover {
    color: #fff;
    background-color: var(--orange);
    svg {
        fill: #fff;
    }
}
</style>

<style scoped lang="scss">
.stack-card {
	border-radius: 5px;
}
.lot-detail:hover {
    text-decoration: underline;
    cursor: pointer;
    color: var(--orange);
}
.grid-display-2 {
    display: grid;
    gap: 1rem;
    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
.grid-display-4 {
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-display-5 {
    display: grid;
    gap: 1rem;
    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
.grid-display-4 {
    display: grid;
    gap: 1rem;
    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
</style>
