<template>
	<div @click="selectElevation(elev)" class="elevation-card">
		<div class="p-3" style="cursor:pointer; width:200px; box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1); border:1px solid #E4E7EB; border-radius:4px">
			<div class="relative" style="height:100%; width:100%">
				<img style="width:100%; height:100px; object-fit:cover" :src="elev.image" />
			</div>
			<h6 class="mt-3" v-if="elev && elev.unitGroup" :style="[elev.id == selectedElev ? {'color': 'var(--orange)'} : {'color': '#000'}]">{{`${elev.unitGroup.name} ${elev.name}`}}</h6>
		</div>
	</div>
</template>

<script>

export default {
    components: {  },
    props: ["elev", "selectedElev"],
	data()	{
		return {
			
		}
	},
    computed: {
        instance() {
            return this.$store.state.instance;
        },
    },
    methods: {
		selectElevation(elev){
			this.$emit('selectElevation', elev)
		}
    },
};
</script>

<style lang="scss">

</style>

<style scoped lang="scss">
	.elevation-card:not(:last-child){
		margin-right: 0.75em;
	}
	.img-cover {
		width:150px;
		height:100px;
		object-fit:cover;
	}
	.elev-image {
		height:0;
		overflow:hidden;
		padding-bottom:66%;
		position: relative;
	}
</style>
