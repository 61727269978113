<template>
    <div
        class="w-full dF fC f1 px-4 pb-4"
        style="overflow-y: scroll"
    >
        <FilterDrawer />
        <HomeDetails />
        <ModelDetails />
        <EmptyApp
            v-if="
                instance.productType == 'lowrise' &&
                Object.values(lots).length == 0
            "
            :text="'Configure lots in Site Plan Configurator to manage inventory'"
            :img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'"
        />
        <EmptyApp
            v-if="
                instance.productType == 'highrise' &&
                Object.values(units).length == 0
            "
            :text="'You don\'t have any units'"
            :img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'"
        />
        <HighriseInventory
            v-if="
                instance.productType == 'highrise' &&
                Object.values(units).length != 0
            "
            :key="$store.state.inventory.newTime"
        />
        <LowriseInventory
            v-if="
                instance.productType == 'lowrise' &&
                Object.values(lots).length != 0
            "
            :key="$store.state.inventory.newTime"
        />
    </div>
</template>

<script>
import EmptyApp from "bh-mod/components/common/EmptyApp";
import HighriseInventory from "@/components/inventory/HighriseInventory";
import LowriseInventory from "@/components/inventory/LowriseInventory";
import FilterDrawer from "@/components/inventory/FilterDrawer";
import HomeDetails from "@/components/inventory/HomeDetails";
import ModelDetails from "@/components/inventory/ModelDetails";

export default {
    components: {
        EmptyApp,
        HighriseInventory,
        LowriseInventory,
        FilterDrawer,
        HomeDetails,
        ModelDetails,
    },
    computed: {
        unitGroups() {
            return this.$store.state.inventory.unitGroups;
        },
        lots() {
            return this.$store.state.inventory.lots;
        },
        units() {
            let units = {};
            if (this.$store.state.instance.productType == "highrise") {
                Object.values(this.$store.state.inventory.units).forEach(
                    (unit) => {
                        if (unit.buildingType == "condounits") {
                            units[unit.id] = unit;
                        }
                    }
                );
            } else if (this.$store.state.instance.productType == "lowrise") {
                Object.values(this.$store.state.inventory.units).forEach(
                    (unit) => {
                        if (unit.buildingType == "models") {
                            units[unit.id] = unit;
                        }
                    }
                );
            }
            return units;
        },
        instance() {
            return this.$store.state.instance;
        },
        tags() {
            return this.$store.state.inventory.tags;
        },
    },
    methods: {
        countStatus(status, id) {
            let array = [];
            if (id == "all") {
                Object.values(this.units).forEach((unit) => {
                    if (unit.salesStatus == status) {
                        array.push(unit);
                    }
                });
            } else {
                Object.values(this.units).forEach((unit) => {
                    if (status != "total") {
                        if (
                            typeof unit.unitGroup == "object" &&
                            unit.unitGroup.id == id &&
                            unit.salesStatus == status
                        ) {
                            array.push(unit);
                        } else if (
                            typeof unit.unitGroup == "string" &&
                            unit.unitGroup == id &&
                            unit.salesStatus == status
                        ) {
                            array.push(unit);
                        }
                    } else {
                        if (
                            typeof unit.unitGroup == "object" &&
                            unit.unitGroup.id == id
                        ) {
                            array.push(unit);
                        } else if (
                            typeof unit.unitGroup == "string" &&
                            unit.unitGroup == id
                        ) {
                            array.push(unit);
                        }
                    }
                });
            }
            return array;
        },
        selectFloor(id) {
            this.$router.push(`/details`);
        },
    },
    created() {
		this.$store.dispatch('FETCH_REALTORS')
        const tags = Object.values(this.tags);
        if (
            (tags.length && tags.filter((x) => x && x.name).length == 0) ||
            !tags.length
        ) {
            this.$store.commit("LOAD", true);
            let type = "models";
            if (this.instance.productType === "lowrise") {
                type = "lots";
            }
            this.$api
                .get(`/tags/:instance?type=${type}`)
                .then(({ data }) => {
                    this.$store.commit("DO_TAGS", data);
                })
                .catch((err) => {
                    this.$message.error("An error occurred. Please try again");
                })
                .finally(() => {
                    this.$store.commit("LOAD", false);
                });
        }
    },
};
</script>

<style scoped>
.display-card {
    border-radius: 3px;
    border-color: rgba(63, 63, 63, 0.15);
    -webkit-box-shadow: 0 1px 3px rgb(63 63 68 / 15%);
    border: none;
    background-color: #fff;
}
.grid-display {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    display: grid;
}
.gap-10 {
    gap: 2.5rem;
}
.gap-5 {
    gap: 1.25rem;
}
</style>
<style lang="scss">
.tab-height .ant-tabs-content {
    height: 100%;
}
.tab-height .empty-app-height {
    height: 100%;
}
</style>
