<template>
    <div class="display-card p-3 dF aT" :data-lot-id="lot.id">
        <div class="dF w-full">
            <div class="f1">
                <div class="dF jSB">
                    <div class="dF aC" style="gap: 5px">
                        <div style="height: 32px" class="mr-2 dF aC">
                            <a-checkbox
                                :checked="selectLot.includes(id)"
                                @change="$emit('toggleSelect', id)"
                            />
                        </div>
                        <h5 style="margin-bottom: 0">
                            {{ `Lot ${lot.name}` }}
                        </h5>
						<small v-if="lot.allocatedTo" style="font-weight:300; background:var(--light-bg-gray); border-radius:40px;" class="px-3 py-2 inline-block">
							<a-badge color="var(--primary)" :text="`${lot.allocatedTo.firstName} ${lot.allocatedTo.lastName}`" />
						</small>
                    </div>
                    <div class="dF aC">
                        <div class="dF aC">
                            <div class="mr-2" style="color: #9ea0a5">
                                Tag(s)
                            </div>
                            <a-select
                                :disabled="
                                    lot.transactionId &&
                                    lot.status !== 'available'
                                "
                                @change="(e) => applyTag(lot, e)"
                                size="small"
                                v-model="lot.tags"
                                mode="multiple"
                                style="width: 200px"
                            >
                                <a-select-option
                                    v-for="tag in tags"
                                    :key="tag.value"
                                    >{{ tag.label }}</a-select-option
                                >
                            </a-select>
                            <!-- <a-input size="small" /> -->
                        </div>
                        <div
                            class="mx-2 px-2 dF aC"
                            style="
                                border-radius: 4px;
                                font-size: 12px;
                                height: 24px;
                            "
							:style="`background-color:${statusColorCode[lot.status] || 'var(--black)'}; color:#FFF`"
                        >
                            <div>
								{{
									salesStatus[lot.status] && salesStatus[lot.status].toUpperCase() || lot.status
                                }}
                            </div>
                        </div>
						<MoreActions
							v-if="$userType === 'agent' && ['available', 'allocated'].includes(lot.status)"
                            trigger="click"
                            :items="showActions(lot)"
                        />
						<a-tooltip
							overlayClassName="change-tooltip-color"
                            placement="topRight"
                            title="Only Owner allowed to perform any action on lot which is not available."
                            v-else-if="$userType === 'agent'"
                        >
                            <a-button
                                :disabled="true"
                                shape="circle"
                                icon="lock"
                            />
                        </a-tooltip>
                        <a-tooltip
							overlayClassName="change-tooltip-color"
                            placement="topRight"
                            :title="
                                lot.transactionId && lot.status === 'sold'
                                    ? 'This lot has been SOLD. A mutual release is required to Void the sale.'
                                    : 'This Lot is locked as its currently in a Transaction. Please check the Transaction App'
                            "
                            v-else-if="
                                lot.transactionId && lot.status !== 'available'
                            "
                        >
                            <a-button
                                :disabled="true"
                                shape="circle"
                                icon="lock"
                            />
                        </a-tooltip>
                        <a-button
                            class="dF aC unsell-button"
                            @click="
                                $p < 40
                                    ? $message.error(
                                          'You do not have permission to unsell this lot.'
                                      )
                                    : $emit('unSell')
                            "
                            style="font-size: 12px"
                            size="small"
                            v-else-if="
                                lot.status == 'sold' ||
                                lot.status == 'conditional'
                            "
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14.558"
                                height="14.094"
                                viewBox="0 0 14.558 14.094"
                            >
                                <path
                                    id="Icon_material-money-off"
                                    data-name="Icon material-money-off"
                                    class="cls-1"
                                    d="M13.827,7.554c1.632,0,2.237.666,2.292,1.644h2.026A3.294,3.294,0,0,0,15.2,6.215V4.5h-2.75V6.191a6.054,6.054,0,0,0-1.357.423l1.348,1.151A4.143,4.143,0,0,1,13.827,7.554ZM7.254,5.33,6.09,6.324,9.244,9.018c0,1.629,1.43,2.513,3.584,3.061l3.218,2.748a2.758,2.758,0,0,1-2.218.713c-1.888,0-2.631-.72-2.732-1.644H9.079c.11,1.715,1.613,2.678,3.374,3v1.7H15.2V16.91a4.973,4.973,0,0,0,2.246-.877l2.035,1.738,1.164-.994Z"
                                    transform="translate(-6.09 -4.5)"
                                />
                            </svg>
                            <div class="ml-1">UNSELL</div>
                        </a-button>
                        <MoreActions
                            v-else
                            trigger="click"
                            :items="showActions(lot)"
                        />
                    </div>
                </div>

                <div class="ml-4">
                    <div class="grid-display-4 mt-3">
                        <div>
                            <div style="color: #9ea0a5">Frontage</div>
                            <h5>{{ getFrontage(lot.id) }}</h5>
                        </div>
                        <div>
                            <div style="color: #9ea0a5">Model Type</div>
                            <h5>{{ getModelType(lot.id) }}</h5>
                        </div>
                        <div>
                            <div style="color: #9ea0a5">
                                Publish Status
                                <span class="ml-1">
                                    <a-tooltip
                                        slot="extra"
                                        overlayClassName="change-tooltip-color"
                                    >
                                        <template slot="title">
                                            In order to change the "Publish
                                            Status", go to Site Plan
                                            Configurator.
                                        </template>
                                        <a-icon
                                            type="question-circle"
                                            style="
                                                line-height: 25px;
                                                margin-top: 5px;
                                                color: var(--orange);
                                            "
                                        />
                                    </a-tooltip>
                                </span>
                            </div>
                            <h5>{{ getPublishStatus(lot.id) }}</h5>
                        </div>
						<div>
                            <div style="color: #9ea0a5">Commission</div>
                            <h5 v-if="lot.commissionStructure && lot.commissionStructure.commissionAmount">
                                {{ lot.commissionStructure.commissionType === 'amount' ? '$': '' }} {{
                                    lot.commissionStructure.commissionAmount.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })
                                }} {{ lot.commissionStructure.commissionType === 'percentage' ? '%': '' }}
                            </h5>
							<h5 v-else-if="agentCommissionStructure && agentCommissionStructure.commissionAmount">
                                {{ agentCommissionStructure.commissionType === 'amount' ? '$': '' }}{{
                                    agentCommissionStructure.commissionAmount.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })
                                }} {{ agentCommissionStructure.commissionType === 'percentage' ? '%': '' }}
                            </h5>
                        </div>
                    </div>
                    <div class="mt-4" style="color: #9ea0a5">
                        Lot Condition / Premium
                    </div>
                    <div
                        :style="
                            lot.other &&
                            lot.other.premiums &&
                            Object.values(lot.other.premiums).filter(
                                (x) => x.active
                            ).length < 6
                                ? 'overflow:hidden'
                                : ''
                        "
                        class="custom-scrollbar"
                        style="height: calc(45px + 1rem); overflow-y: scroll"
                    >
                        <div
                            v-if="checkOther(lot.id, 'premiums')"
                            class="grid-display-3"
                        >
                            <template
                                v-for="premium in Object.keys(
                                    lot.other.premiums
                                )"
                            >
                                <h5
                                    :key="premium"
                                    v-if="
                                        storePremiums[premium] &&
                                        lot.other.premiums[premium].active
                                    "
                                >
                                    {{ storePremiums[premium].name }}
                                </h5>
                            </template>
                        </div>
                        <h5 v-else>N/A</h5>
                    </div>
                    <div class="grid-display-3 mt-4">
                        <div>
                            <div style="color: #9ea0a5">Address</div>
                            <h5>{{ getAddress(lot.id) }}</h5>
                        </div>
                        <div>
                            <div style="color: #9ea0a5">Block #</div>
                            <h5>
                                {{
                                    lot.block && lot.block != ""
                                        ? lot.block
                                        : "N/A"
                                }}
                            </h5>
                        </div>
                        <div>
                            <div style="color: #9ea0a5">
                                Exterior Color Package
                            </div>
                            <h5>
                                {{ (lot.brick && lot.brick.name) || "N/A" }}
                            </h5>
                        </div>
                    </div>
                    <div style="color: #9ea0a5" class="mt-4">Lot Grading</div>
                    <div
                        v-if="checkOther(lot.id, 'gradings')"
                        class="grid-display-3"
                    >
                        <template
                            v-for="grade in Object.keys(lot.other.gradings)"
                        >
                            <h5 :key="grade" v-if="storeGradings[grade]">
                                {{ storeGradings[grade].name }}
                            </h5>
                        </template>
                    </div>
                    <h5 v-else>N/A</h5>

                    <hr />
                    <div v-if="lot.attachedUnit">
                        <div style="color: #9ea0a5">
                            {{
                                lot.status == "conditional" || lot.status == 'soldConditional'
                                    ? "Conditional Elevation"
                                    : lot.status == "hold"
                                    ? "Hold Elevation"
                                    : lot.status == "inventory"
                                    ? "Standing Inventory Elevation"
                                    : "Sold Elevation"
                            }}
                        </div>
                        <div class="mt-3" style="height: calc(45px + 1rem)">
							<strong @click="showUnit(lot.attachedUnit)" class="lot-detail">{{ getUnitName(lot.attachedUnit) }}</strong>
                        </div>
                    </div>
                    <div v-else>
                        <div class="dF jSB aC">
                            <div style="color: #9ea0a5">Available Home(s)</div>
                            <svg
                                @click="refresh(lot.id)"
                                style="cursor: pointer"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-refresh-ccw"
                            >
                                <polyline points="1 4 1 10 7 10"></polyline>
                                <polyline points="23 20 23 14 17 14"></polyline>
                                <path
                                    d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"
                                ></path>
                            </svg>
                        </div>
                        <div
                            class="custom-scrollbar mt-3"
                            style="
                                height: calc(45px + 1rem);
                                overflow-y: scroll;
                            "
                        >
                            <div class="grid-display-3">
                                <template v-if="!availableUnits[lot.id]">
                                    <div
                                        class="lot-detail"
                                        @click="showUnit(unit)"
                                        v-for="unit in lot.units"
                                        :key="unit"
                                    >
                                        <strong>{{ getUnitName(unit) }}</strong>
                                    </div>
                                </template>
                                <template v-else>
                                    <div
                                        class="lot-detail"
                                        @click="showUnit(unit)"
                                        v-for="unit in availableUnits[lot.id]"
                                        :key="unit"
                                    >
                                        <strong>{{ getUnitName(unit) }}</strong>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MoreActions from "bh-mod/components/common/MoreActions";
export default {
    components: { MoreActions },
    props: ["id", "selectLot"],
	data() {
		return {
			salesStatus: {
				available: "Available",
				sold: "Sold",
				hold: "Hold",
				conditional: "Sold Conditional",
				inventory: "Standing Inventory",
				notreleased: "Not Released",
				allocated: "Allocated",
				approved_for_reservation: "Approved",
				reserved: "Reserved"
			},
			statusColorCode: {
				available: "#1EC48C",
				sold: "#FD6C83",
				hold: "#FECE5B",
				conditional: "#9693E8",
				inventory: "#3395c6",
				notreleased: "#3F3356",
				allocated: "var(--primary)",
				approved_for_reservation: "#134F5C",
				reserved: "#419E00"
			}
		}
	},
    computed: {
        availableUnits() {
            return this.$store.state.inventory.availableUnits || {};
        },
        storeGradings() {
            return this.$store.state.inventory.gradings;
        },
        storePremiums() {
            return this.$store.state.inventory.premiums;
        },
        storeLots() {
            return this.$store.state.inventory.lots;
        },
        models() {
            let models = {};
            let units = JSON.parse(
                JSON.stringify(this.$store.state.inventory.units)
            );
            Object.values(units).forEach((unit) => {
                if (unit.buildingType == "models") {
                    let model = {};
                    let price = [];
                    model = JSON.parse(JSON.stringify(unit));
                    model.packages.forEach((x) => {
                        if (!x) return;
                        if (x.price != 0 || x.price != "") {
                            price.push(x.price);
                        }
                    });
                    if (price.length != 0) {
                        let min = Math.min(...price);
                        let max = Math.max(...price);
                        if (min != max) {
                            model.displayPrice = `${min}+`;
                        } else if (min == max) {
                            model.displayPrice = `${min}`;
                        }
                    } else {
                        model.displayPrice = "N/A";
                    }
                    models[model.id] = model;
                }
            });
            return models;
        },
		lot() {
            const selectedLot = this.$store.state.inventory.lots[this.id] || {};
			if(selectedLot.tags && selectedLot.tags.length && this.tags.length){
				selectedLot.tags = selectedLot.tags.filter(id => {
					return this.tags.find(t => t.value === id)
				})
			}
			return selectedLot;
        },
        units() {
            let units = {};
            Object.values(this.$store.state.inventory.units).forEach((unit) => {
                if (unit.buildingType == "models") {
                    units[unit.id] = unit;
                }
            });
            return units;
        },
        storeTags() {
            return this.$store.state.inventory.tags || {};
        },
        tags() {
            let list = [];

            if (Object.keys(this.storeTags).length) {
                let tags = Object.values(this.storeTags).filter(
                    (x) => x && x.name && x.id && x.type == "lots"
                );
                tags.forEach((tag) => {
                    if (tag && tag.id && tag.name) {
                        let obj = {};
                        obj.value = tag.id;
                        obj.label = tag.name;
                        list.push(obj);
                    }
                });
            }
            return list;
        },

		appSettings() {
			let app = this.$store.state.inventory.allSettings.app;
			if (!app) app = { options: {} };
			return app.options;
		},

		agentCommissionStructure() {
			return {
				commissionType: this.appSettings.agentCommissionStructure.lotCommissionType || 'percentage',
				commissionAmount: this.appSettings.agentCommissionStructure.lotCommissionAmount || null
			}
		},
    },
    methods: {
        async showUnit(id) {
            let units = [];
            this.$store.commit("LOAD", true);
            if (!this.availableUnits[this.lot.id]) {
                await this.$api
                    .get(
                        `/architectural-controls/:instance/availableunits/${this.lot.id}`
                    )
                    .then(({ data }) => {
                        units = data.units.map((x) => {
                            if (typeof x === "object" && x.id) return x.id;
                            return x;
                        });
                        this.$store.commit("UPDATE_AVAIL_UNITS", {
                            id: this.lot.id,
                            units: units,
                        });
                    })
                    .catch((err) => {
                        this.$message.error(
                            "An error occurred. Please try again"
                        );
                        console.error(err);
                    })
                    .finally(() => {
                        this.$store.commit("LOAD", false);
                    });
            } else {
                units = this.availableUnits[this.lot.id];
            }
            this.$store.commit("OPEN_HOME_DETAILS", {
                units: units,
                unit: id,
                lot: this.lot.id,
            });
            this.$store.commit("LOAD", false);
        },
        refresh(id) {
            if (!this.availableUnits[id]) {
                this.$store.commit("LOAD", true);
                this.$api
                    .get(
                        `/architectural-controls/:instance/availableunits/${id}`
                    )
                    .then(({ data }) => {
                        data.units = data.units.map((x) => {
                            if (typeof x === "object" && x.id) return x.id;
                            return x;
                        });
                        this.$store.commit("UPDATE_AVAIL_UNITS", {
                            id: id,
                            units: data.units,
                        });
                    })
                    .catch((err) => {
                        this.$message.error(
                            "An error occurred. Please try again"
                        );
                        console.error(err);
                    })
                    .finally(() => {
                        this.$store.commit("LOAD", false);
                    });
            }
        },
        applyTag(lot, e) {
            this.$emit("applyTags", lot, e);
        },
        checkOther(id, type) {
            if (this.storeLots[id].other && this.storeLots[id].other[type]) {
                let premiums = Object.keys(this.storePremiums);
                let gradings = Object.keys(this.storeGradings);
                let list = [];
                if (type == "gradings") {
                    Object.keys(this.storeLots[id].other[type]).forEach((x) => {
                        if (
                            gradings.includes(x) &&
                            this.storeLots[id].other[type][x].active
                        ) {
                            list.push(x);
                        }
                    });
                }
                if (type == "premiums") {
                    Object.keys(this.storeLots[id].other[type]).forEach((x) => {
                        if (
                            premiums.includes(x) &&
                            this.storeLots[id].other[type][x].active
                        ) {
                            list.push(x);
                        }
                    });
                }
                if (list.length) return true;
                else return false;
            }
            return false;
        },
        getAddress(id) {
            let name = "";
            if (this.storeLots[id].street && this.storeLots[id].street != "") {
                name = name + this.storeLots[id].street;
            }
            if (
                name != "" &&
                this.storeLots[id].streetNumber &&
                this.storeLots[id].streetNumber != ""
            ) {
                name = this.storeLots[id].streetNumber + " " + name;
            }
            if (
                name != "" &&
                this.storeLots[id].postal &&
                this.storeLots[id].postal != ""
            ) {
                name = name + `, ${this.storeLots[id].postal}`;
            }
            if (
                name == "" &&
                this.storeLots[id].postal &&
                this.storeLots[id].postal != ""
            ) {
                name = name + this.storeLots[id].postal;
            }
            if (name == "") name = "N/A";
            return name;
        },
        calculatePrice(id) {
            let price = 0;
            let more = [];
            this.storeLots[id].units.forEach((unit) => {
                let modelPrice = "";
                let unitId = "";
                if (typeof unit == "string") unitId = unit;
                else if (typeof unit == "object") unitId = unit.id;
                if (!this.models[unitId]) modelPrice = 0;
                else {
                    if (
                        this.models[unitId] &&
                        this.models[unitId].displayPrice &&
                        this.models[unitId].displayPrice.includes("+")
                    ) {
                        modelPrice = this.models[unitId].displayPrice.substring(
                            0,
                            this.models[unitId].displayPrice.length - 1
                        );
                        modelPrice = parseFloat(modelPrice);
                        more.push(modelPrice);
                    } else {
                        modelPrice = parseFloat(
                            this.models[unitId].displayPrice
                        );
                    }
                    if (this.models[unitId].displayPrice == "N/A") {
                        modelPrice = 0;
                    }
                }
                price = price + modelPrice;
            });
            if (price == 0) price = "N/A";
            if (more.length) price = `${price.toLocaleString("us-en")}+`;
            return price;
        },
        showActions(lot) {
            let { status } = lot;
			if(this.$userType === 'agent'){
				return [{
                    label: "Fill Up Worksheet",
                    value: "createWorksheet",
                    val: "worksheet",
                    action: () => this.$emit("createWorksheet"),
                    if: status === "available",
                    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>',
                }];
			}
            return [
                {
                    label: "Update Status",
                    value: "updateStatus",
                    val: "status",
                    action: () => this.$emit("updateStatus"),
                    if: !status.includes("sold"),
                    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg>',
                },
                {
                    label: "Make a Transaction",
                    value: "makeTransaction",
                    val: "transaction",
                    action: () => this.$emit("makeTransaction"),
                    if: !status.includes("sold"),
                    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>',
                },
                {
                    label: "Unsell Lot",
                    value: "unsell",
                    action: () => this.$emit("unSell"),
                    if: status.includes("sold") && this.$p >= 40,
                },
            ].filter((x) => x.if);
        },
        getUnitName(unit) {
            let id = unit.id ? unit.id : unit;
            let result = "N/A";
            unit = this.units[id];
            if (unit) result = unit.name;
            if (
                unit &&
                unit.hasOwnProperty("unitGroup") &&
                unit.unitGroup &&
                unit.unitGroup.name
            )
                result = `${unit.unitGroup.name} - ${unit.name}`;
            return result;
        },
        getPublishStatus(id) {
            let status = "Draft";
            if (
                this.storeLots[id] &&
                this.storeLots[id].publishStatus == "published"
            ) {
                status = "Released";
            } else {
                status = "Draft";
            }
            return status;
        },
        getModelType(id) {
            let types = [];
            if (
                this.storeLots[id] &&
                this.storeLots[id].units &&
                this.storeLots[id].units.length
            ) {
                this.storeLots[id].units.forEach((unit) => {
                    let id = "";
                    if (typeof unit == "string") id = unit;
                    else if (typeof unit == "object") id = unit.id;

                    if (
                        this.units[id] &&
                        !types.includes(this.units[id].unitGroup.type)
                    ) {
                        types.push(this.units[id].unitGroup.type);
                    }
                });
                if (types.length > 1) {
                    types = "Various";
                } else if (types.length == 1) {
                    types = types[0];
                }
            } else {
                return (types = "N/A");
            }
            return types;
        },
        getFrontage(id) {
            let frontage = [];
            if (!this.storeLots[id].units.length) {
                return "N/A";
            } else {
                this.storeLots[id].units.forEach((unit) => {
                    let id = "";
                    if (typeof unit == "string") id = unit;
                    else if (typeof unit == "object") id = unit.id;
                    if (
                        this.units[id] &&
                        !frontage.includes(this.units[id].unitGroup.size)
                    ) {
                        frontage.push(this.units[id].unitGroup.size);
                    }
                });
                if (frontage.length == 1) {
                    return frontage[0];
                } else if (frontage.length > 1) {
                    let min = Math.min(...frontage);
                    let max = Math.max(...frontage);
                    return `${min}-${max}`;
                }
            }
        },
        getUnits(id) {
            let units = [];
            if (
                this.storeLots[id] &&
                this.storeLots[id].units &&
                this.storeLots[id].units.length
            ) {
                this.storeLots[id].units.forEach((unit) => {
                    if (this.units[unit]) {
                        units.push(
                            `${this.units[unit].unitGroup.name} - ${this.units[unit].name}`
                        );
                    }
                });
            }
            return units;
        },
        getPremium(id) {
            let price = 0;
            let premiums =
                (this.storeLots[id].other &&
                    this.storeLots[id].other.premiums) ||
                {};
            premiums = Object.values(premiums).filter((x) => x.active);

            if (premiums.length == 0) return 0;
            else {
                premiums.forEach((x) => {
                    price = price + x.price;
                });
                return price;
            }
        },
        getPremiums(id) {
            let premiums =
                (this.storeLots[id].other &&
                    this.storeLots[id].other.premiums) ||
                {};
            premiums = Object.values(premiums).filter((x) => x.active);

            if (premiums.length == 0) return "";
            return premiums.map((x) => {
                return `${x.name} - $${x.price.toLocaleString("us-en")}`;
            });
        },
        getGradings(id) {
            let gradings =
                (this.storeLots[id].other &&
                    this.storeLots[id].other.gradings) ||
                {};
            gradings = Object.values(gradings).filter((x) => x.active);

            if (gradings.length == 0) return "";
            return gradings.map((x) => {
                return `${x.name} - $${x.price.toLocaleString("us-en")}`;
            });
        },
        getCondition(id) {
            let conditions = {
                none: "None",
                sideRearUpgrade: "Side and Rear Upgrade",
                wob: "Walk-out Basement",
                rearUpgrade: "Rear Upgrade",
                other: "Other",
                cornerUpgrade: "Corner Upgrade",
            };
            let conds = [];
            let lot = this.storeLots[id];
            let other = null;
            if (!lot.condition) lot.condition = [];

            if (typeof lot.condition == "string") {
                return [conditions[lot.condition]];
            } else {
                lot.condition.forEach((x) => {
                    if (x === "other" && lot.notes && lot.notes.length) {
                        other = `Other: ${lot.notes}`;
                    } else if (x != "none") {
                        conds.push(conditions[x]);
                    }
                });

                if (other) conds.push(other);
                return conds;
            }
        },
    },
};
</script>

<style lang="scss">
.unsell-button.ant-btn {
    border: 1px solid var(--orange);
    color: var(--orange);
    svg {
        fill: var(--orange);
    }
}
.unsell-button.ant-btn:hover {
    color: #fff;
    background-color: var(--orange);
    svg {
        fill: #fff;
    }
}
</style>

<style scoped lang="scss">
.lot-detail:hover {
    text-decoration: underline;
    cursor: pointer;
    color: var(--orange);
}
.grid-display-2 {
    display: grid;
    gap: 1rem;
    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
.grid-display-3 {
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-display-3 {
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-display-5 {
    display: grid;
    gap: 1rem;
    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
</style>
