<template>
    <div>
        <BulkTags
            :visible="showBulkTags"
            @applyBulkTags="applyBulkTags"
            @closeBulkTags="closeBulkTags"
            :selectedUnits="selectUnit"
            :units="units"
        />
		<BulkStatus
            :visible="showBulkStatus"
            @applyBulkStatus="applyBulkStatus"
            @closeBulkStatus="closeBulkStatus"
            :selectedUnits="selectUnit"
            :units="units"
        />
		<div class="dF aC jSB">
        	<h3>Overview</h3>
			<div class="aC" style="display: grid; grid-template-columns: repeat(3, minmax(0, 1fr)); gap: 0.5rem;">
				<div style="cursor: pointer;" @click="onViewChange('grid')">
					<i :style="currentView == 'grid' ? 'color:var(--orange)' : 'color:#D3D0D6'" style="cursor:pointer; font-size:25px" class="fa fa-th" />
				</div>
				<!-- <div style="cursor: pointer;" @click="onViewChange('list')">
					<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" :stroke="currentView == 'list' ? `var(--orange)` : `#303B42`" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
				</div> -->
				<div v-if="Object.values(unitGroups) && Object.values(unitGroups).length && Object.values(unitGroups)[0].siteplan && Object.values(unitGroups)[0].siteplan.shapes && Object.values(unitGroups)[0].siteplan.shapes.length" style="cursor: pointer;" @click="onViewChange('map')">
					<svg :fill="currentView == 'map' ? `var(--orange)` : `#D3D0D6`" width="25" height="25" viewBox="1181.75 1347.686 26.275 23.732"> <path d="M1208.275 1347.936h-4.66v2.543h2.117v5.424h-2.034v2.543h2.034v10.679h-8.73v-4.661h-2.543v4.661h-9.916v-12.29h5.763v-2.542h-5.763v-3.814h9.916v9.916h2.543v-1.95h2.204v-2.542h-2.204v-5.424h1.78v-2.543H1182v23.732h26.275v-23.732Z"/> </svg>
				</div>
				<div v-if="Object.values(unitGroups) && Object.values(unitGroups).length" style="cursor: pointer;" @click="onViewChange('stack')">
					<svg :stroke="currentView == 'stack' ? `var(--orange)` : `#D3D0D6`" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
				</div>
			</div>
		</div>
		<template v-if="currentView == 'grid'">
			<div class="dF" style="flex-wrap: wrap;">
				<div
					v-for="stat in unitOverview"
					:key="stat.value"
					class="overview-card mb-3 mr-3"
					style="width: 135px;"
				>
					<h5 style="min-height: 45px;">{{ stat.label }}</h5>
					<div
						style="font-size: 25px; margin-top: 10px"
						:style="`color:${stat.color}`"
					>
						{{ overviewCount[stat.value] }}
					</div>
				</div>
			</div>
			<div class="my-4">
				<div class="dF aC jSB" style="gap: 15px">
					<div class="dF aC">
						<h3 class="mr-3">Units</h3>

						<div class="dF" style="flex-wrap: wrap;">
							<div
								v-for="(stat) in unitOverview"
								:key="stat.value"
								:style="
									stat.value != 'total'
										? `background-color:${stat.color};`
										: 'border:1px solid #D0C9D6'
								"
								style="border-radius: 4px"
								class="px-3 py-1 mb-2 mr-2"
							>
								<a-checkbox
									@change="(e) => changeSelect(stat.value, e)"
									:checked="selectType.includes(stat.value)"
									:style="
										stat.value != 'total' && stat.value != 'hold'
											? 'color:#FFF'
											: ''
									"
									>{{ stat.select }}</a-checkbox
								>
							</div>
						</div>
					</div>
					<div class="dF aC ml-5">
						<MoreActions
							v-if="selectUnit.length > 1"
							shape="button"
							buttonText="Bulk Actions"
							:items="bulkActions"
							@action="bulkActioned"
						/>
						<div
							@click="openFilter"
							class="dF aC px-3 py-1 mx-2"
							style="
								border-radius: 3px;
								background-color: #fff;
								cursor: pointer;
								border: 1px solid #ecebed;
							"
						>
							<div>Filters</div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-filter ml-2"
							>
								<polygon
									points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"
								></polygon>
							</svg>
						</div>
					</div>
				</div>
			</div>
			<div v-if="units.length" class="grid-display-2">
				<div v-for="unit in units" :key="unit.id">
					<UnitCard
						@toggleSelect="toggleSelect"
						@filterSimilarUnits="filterSimilarUnits(unit)"
						:id="unit.id"
						:selectUnit="selectUnit"
						@updateStatus="updateStatus(unit)"
						@unSell="unsellUnit(unit.id)"
						@applyTags="updateTags"
						@makeTransaction="makeTransaction(unit)"
						@createWorksheet="createWorksheet(unit)"
					/>
				</div>
			</div>
			<div v-else>
				<EmptyApp
					:text="'No units found for your filter'"
					:img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'"
				/>
			</div>
		</template>
		<div v-show="currentView == 'map'" class="f1 relative">
			<BhLoading :show="loading" />
			<div class="w-full dF" style="user-select: none;">
				<div class="f1 dF fC relative">
					<div id="siteplan-lot-tooltip" class="siteplan-tooltip-style1" role="tooltip" style="z-index:3; opacity: 0">
						<div>
							<span>My tooltip</span>
						</div>
					</div>

					<!-- Filters -->
					<div class="w-full dF aS" style="border: 1px solid #D0C9D6; background-color:#FFFFFF; font-size: 12px;">
						<div class="f1 filter-item">
							<fieldset class="filter-price" style="padding: 1.2rem 20px;">
								<div class="mt-2 price-field">
									<input data-price-filter-lower type="range"  min="0" max="500" value="0">
									<input data-price-filter-upper type="range" min="1" max="500" value="500">
								</div>
								<div class="w-full mt-4 dF jSB price-label">
									<span data-price-filter-lower-value></span>
									<span data-price-filter-upper-value></span>
								</div>
							</fieldset>
						</div>

						<div class="f1 filter-item relative">
							<select data-bed-filter class="dropdown-filter">
								<option value="all">All</option>
							</select>
							<div style="padding: 1.2rem 20px;">
								<div class="dF aC jSB">
									<span style="color: #9EA0A5;">BEDS</span>
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
								</div>
								<div data-bed-filter-value style="color: #000">All</div>
							</div>
						</div>

						<div class="f1 filter-item relative">
							<select data-den-filter class="dropdown-filter">
								<option value="all">All</option>
								<option value="yes">Yes</option>
								<option value="no">No</option>
							</select>
							<div style="padding: 1.2rem 20px;">
								<div class="dF aC jSB">
									<span style="color: #9EA0A5;">Has Den?</span>
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
								</div>
								<div data-den-filter-value style="color: #000">All</div>
							</div>
						</div>

						<div class="f1 filter-item relative">
							<select data-bath-filter class="dropdown-filter">
								<option value="all">All</option>
							</select>
							<div style="padding: 1.2rem 20px;">
								<div class="dF aC jSB">
									<span style="color: #9EA0A5;">BATHS</span>
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
								</div>
								<div data-bath-filter-value style="color: #000">All</div>
							</div>
						</div>

						<div class="f1 filter-item relative">
							<select data-sqft-filter class="dropdown-filter">
								<option value="all">All</option>
							</select>
							<div style="padding: 1.2rem 20px;">
								<div class="dF aC jSB">
									<span style="color: #9EA0A5;">SQ. FT.</span>
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
								</div>
								<div data-sqft-filter-value style="color: #000;">All</div>
							</div>
						</div>

						<div class="f1 filter-item relative d-none d-lg-flex">
							<select data-exposure-filter class="dropdown-filter">
								<option value="all">All</option>
							</select>
							<div style="padding: 1.2rem 20px;">
								<div class="dF aC jSB">
									<span style="color: #9EA0A5;">EXPOSURE</span>
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
								</div>
								<div data-exposure-filter-value style="color: #000;">All</div>
							</div>
						</div>
						<div class="dF aC jC" style="padding: 1.2rem 20px;">
							<div data-clear-button class="px-5 py-2 h-full" style="border: 1px solid #D0C9D6; cursor: pointer;">
								Clear Filters
							</div>
						</div>
					</div>

					<div style="background:#E8E8E8; flex:1; position:relative; min-height: calc(100vh - 290px)" class="siteplan-holder">
						<canvas data-siteplan-canvas></canvas>

						<!-- Floor filter -->
						<div data-floor-filter style="cursor: pointer; user-select: none; top: 20px; left: 20px; position: absolute; z-index: 2;">
							<div data-floor-filter-dropdown class="filter-dropdown dF aC">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
								<div class="ml-3" style="line-height: 1.5; font-size: .75rem; width: 200px;">
									<div style="color: #9EA0A5;">FLOOR</div>
									<div>All Levels</div>
								</div>
								<div class="flex items-center justify-center">
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
								</div>
							</div>
							<div data-floor-filter-expanded class="filter-dropdown-expanded" style="display: none; overflow-y: scroll; max-height: 180px;">
							</div>
						</div>

						<!-- Tools -->
						<div class="d-none d-lg-block absolute dF jE px-4 pb-4" style="top: 1em; right: 0; z-index: 2;">
							<svg width="30" height="30" viewBox="0 0 49.001 48.999" class="mr-2 zoomOut"  @click="zoom('out')">
								<path d="M-7588-2665.5h-42a3.5,3.5,0,0,1-3.5-3.5v-42a3.5,3.5,0,0,1,3.5-3.5h42a3.5,3.5,0,0,1,3.5,3.5v42A3.5,3.5,0,0,1-7588-2665.5Zm-30.591-26.865h0a1.661,1.661,0,0,0-1.659,1.659v1.661a1.662,1.662,0,0,0,1.659,1.661h19.93a1.663,1.663,0,0,0,1.661-1.661v-1.661a1.662,1.662,0,0,0-1.661-1.659c-.061.05-6.96.112-12.512.112C-7613.835-2692.253-7618.34-2692.268-7618.59-2692.365Z" transform="translate(7633.5 2714.5)" fill="#333333" opacity="0.812"/>
							</svg>
							<svg width="30" height="30" viewBox="0 0 49.001 48.999" class="zoomIn" @click="zoom('in')">
								<path  d="M-7588-2665.5h-42a3.5,3.5,0,0,1-3.5-3.5v-42a3.5,3.5,0,0,1,3.5-3.5h42a3.5,3.5,0,0,1,3.5,3.5v42A3.5,3.5,0,0,1-7588-2665.5Zm-31.091-26.865a1.661,1.661,0,0,0-1.659,1.659v1.661a1.662,1.662,0,0,0,1.659,1.661h7.474v7.473a1.663,1.663,0,0,0,1.661,1.661h1.661a1.663,1.663,0,0,0,1.661-1.661v-7.473h7.473a1.663,1.663,0,0,0,1.661-1.661v-1.661a1.662,1.662,0,0,0-1.661-1.659h-7.473v-7.473a1.663,1.663,0,0,0-1.661-1.661h-1.661a1.663,1.663,0,0,0-1.661,1.661v7.473Z" transform="translate(7633.5 2714.5)" fill="#333333" opacity="0.812"/>
							</svg>
						</div>
					</div>
				</div>
			</div>
		</div>
		<template v-if="currentView == 'stack'">
			<div class="dF" style="flex-wrap: wrap;">
				<div
					v-for="stat in unitOverview"
					:key="stat.value"
					class="overview-card mb-3 mr-3"
					style="width: 135px;"
				>
					<h5 style="min-height: 45px;">{{ stat.label }}</h5>
					<div
						style="font-size: 25px; margin-top: 10px"
						:style="`color:${stat.color}`"
					>
						{{ overviewCount[stat.value] }}
					</div>
				</div>
			</div>
			<div class="my-4">
				<div class="dF aC jSB" style="gap: 15px">
					<div class="dF aC">
						<h3 class="mr-3">Units</h3>

						<div class="dF" style="flex-wrap: wrap;">
							<div
								v-for="(stat) in unitOverview"
								:key="stat.value"
								:style="
									stat.value != 'total'
										? `background-color:${stat.color};`
										: 'border:1px solid #D0C9D6'
								"
								style="border-radius: 4px"
								class="px-3 py-1 mb-2 mr-2"
							>
								<a-checkbox
									@change="(e) => changeSelect(stat.value, e)"
									:checked="selectType.includes(stat.value)"
									:style="
										stat.value != 'total' && stat.value != 'hold'
											? 'color:#FFF'
											: ''
									"
									>{{ stat.select }}</a-checkbox
								>
							</div>
						</div>
					</div>
					<div class="dF aC ml-5">
						<MoreActions
							v-if="selectUnit.length > 1"
							shape="button"
							buttonText="Bulk Actions"
							:items="bulkActions"
							@action="bulkActioned"
						/>
						<a-popover placement="bottom" trigger="click">
							<a-button style="margin-left: 8px">Display<a-icon type="down" /> </a-button>
							<div slot="content">
								<strong>Align Units</strong>
								<div class="mt-3 dF">
									<div>
										<div class="mb-1">SQFT</div>
										<a-switch :checked="displayType == 'sqft' ? true : false" @change="updateDisplayType('sqft')" />
									</div>
								</div>
							</div>
						</a-popover>
						<div
							@click="openFilter"
							class="dF aC px-3 py-1 mx-2"
							style="
								border-radius: 3px;
								background-color: #fff;
								cursor: pointer;
								border: 1px solid #ecebed;
							"
						>
							<div>Filters</div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-filter ml-2"
							>
								<polygon
									points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"
								></polygon>
							</svg>
						</div>
					</div>
				</div>
			</div>
			<div v-if="units.length">
				<div v-for="(floor,floorI) in Object.values(unitGroups).toReversed()" :key="floor+floorI" class="stack-row dF aC relative h-full">
					<div class="mr-2 py-3 dF aC jC" style="background-color: #aaa; color: #fff; height: 60px; width:35px;">{{ floor.order }}</div>
					<div class="f1 dF row-scroll" style="background-color: var(--light-divider); height: 60px; overflow-x: scroll; overflow-y: hidden; white-space: nowrap;">
						<template v-for="(unit,unitI) in units">
							<StackCard
								v-if="unit.unitGroup && unit.unitGroup.id == floor.id"
								:unit="unit"
								:id="unit.id"
								:displayType="displayType"
								:key="unit+unitI"
								@updateStatus="updateStatus(unit)"
								@unSell="unsellUnit(unit.id)"
								@makeTransaction="makeTransaction(unit)"
								@createWorksheet="createWorksheet(unit)"></StackCard>
						</template>
					</div>
				</div>
			</div>
			<div v-else>
				<EmptyApp
					:text="'No units found for your filter'"
					:img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'"
				/>
			</div>
		</template>

        <a-modal
            :visible="modal.visible"
            @cancel="onClose"
            :centered="true"
            :footer="null">
            <h5 v-if="modal.object.name">
                {{ `Unit ${modal.object.unitNumber}` }}
                <span class="ml-3" :style="`color:${statusColorCode[modal.object.salesStatus]}`">
					{{ salesStatus[modal.object.salesStatus] }}
				</span>
            </h5>
            <a-form-model
                v-if="modal.type == 'updateStatus'"
                ref="status"
                :model="status"
                class="mt-5"
            >
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item
                            label="Status"
                            required
                            :rules="req('Please select one')"
                            prop="status"
                        >
                            <a-select
                                @change="changeStatus"
                                v-model="status.status"
                            >
                                <a-select-option
                                    v-for="stat in statusOptions"
                                    :key="stat.value"
                                    :value="stat.value"
                                    >{{ stat.label }}</a-select-option
                                >
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col
                        :span="24"
                        v-if="status.status === 'sold'"
                        class="mb-4"
                    >
                        <div style="font-size: 15px; line-height: 1">
                            Unit Add Ons
                        </div>
                        <a-tabs
                            default-active-key="1"
                            :tabBarStyle="{ 'border-bottom': 'none' }"
                            @change="changeTab"
                        >
                            <a-tab-pane key="1" tab="Parking">
                                <a-select
                                    v-model="status.addons.parking"
                                    mode="multiple"
                                >
                                    <a-select-option
                                        v-for="p in addOns.parking"
                                        :key="p.id"
                                        :value="p.id"
                                        :disabled="!!p.disabled"
                                        >{{ p.name }}</a-select-option
                                    >
                                </a-select>
                            </a-tab-pane>

                            <a-tab-pane key="2" tab="Locker">
                                <a-select
                                    v-model="status.addons.lockers"
                                    mode="multiple"
                                >
                                    <a-select-option
                                        v-for="l in addOns.lockers"
                                        :key="l.id"
                                        :value="l.id"
                                        :disabled="!!l.disabled"
                                        >{{ l.name }}</a-select-option
                                    >
                                </a-select>
                            </a-tab-pane>

                            <a-tab-pane key="3" tab="Bike Rack">
                                <a-select
                                    v-model="status.addons.bikeRacks"
                                    mode="multiple"
                                >
                                    <a-select-option
                                        v-for="b in addOns.bikeRacks"
                                        :key="b.id"
                                        :value="b.id"
                                        :disabled="!!b.disabled"
                                        >{{ b.name }}</a-select-option
                                    >
                                </a-select>
                            </a-tab-pane>
                        </a-tabs>
                    </a-col>

                    <a-col
                        :span="24"
                        v-if="
                            status.status == 'sold' ||
                            status.status == 'conditional'
                        "
                    >
                        <a-form-model-item label="Final Price" prop="price">
                            <a-input-number
                                style="width: 100%"
                                v-model="status.price"
                                :formatter="
                                    (price) =>
                                        `$ ${price}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ','
                                        )
                                "
                                :min="0"
                            ></a-input-number>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24" v-if="status.status == 'sold'">
                        <a-form-model-item
                            label="Sales Owner"
                            prop="owner"
                            :rules="req('Please select one')"
                        >
                            <a-select
                                v-model="status.owner"
                                placeholder="Select one"
                                size="large"
                            >
                                <a-select-option
                                    v-for="user in users"
                                    :key="user.id"
                                    :value="user.id"
                                    >{{
                                        `${user.firstName} ${user.lastName}`
                                    }} ({{ user.email }})</a-select-option
                                >
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24" v-if="status.status == 'sold'">
                        <a-form-model-item
                            label="Purchasers"
                            prop="purchasers"
                            required
                            :rules="req('Please add purchasers')"
                        >
                            <a-select
                                v-model="status.purchasers"
                                placeholder="Select add purchasers"
                                mode="tags"
                                size="large"
                            >
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
            <div v-else-if="modal.type === 'applyTags'" class="py-3">
                <h5>Apply Tags</h5>
                <div
                    v-for="item in $store.state.inventory.tags"
                    :key="item.id"
                    @click="applyTags(item.id)"
                    :style="
                        applyingTags.includes(item.id)
                            ? 'background-color: #CAC3D0'
                            : ''
                    "
                    class="py-1 px-2 filter-options my-4"
                    style="
                        border: #d0c9d6 1px solid;
                        border-radius: 6px;
                        width: 100%;
                        cursor: pointer;
                        text-align: center;
                    "
                >
                    {{ item.name }}
                </div>
            </div>
            <div style="width: 100%" class="dF jE">
                <div class="dF aC">
                    <a-button
                        :style="{ marginRight: '8px' }"
                        @click="onClose"
                        style="
                            border: none !important;
                            background-color: #ece9f1;
                            width: 150px;
                        "
                        >CANCEL</a-button
                    >
                    <a-button
                        v-if="modal.type === 'applyTags'"
                        @click="updateTags"
                        type="primary"
                        style="width: 150px"
                        >UPDATE TAGS</a-button
                    >
                    <a-button
                        v-else-if="modal.type === 'updateStatus'"
                        @click="submit"
                        type="primary"
                        style="width: 150px"
                        >UPDATE</a-button
                    >
                </div>
            </div>
        </a-modal>

        <a-modal
            :visible="showTMInfoTip"
            @cancel="
                showTMInfoTip = false;
                doNotShowTMInfoTipAgain = false;
            "
            :centered="true"
            title="Tip"
            okText="CONTINUE"
            @ok="tmInfoUpdate"
        	>
            <h6>
                When using Bildhive’s transaction management (TM) use only the
                hold option. Applying “conditional or sold options” will remove
                the model/unit and will therefore not show up in the (TM)
                application.
            </h6>
            <h6 class="mt-4">
                If you’re not using TM as part of your process, feel free to use
                all status options to manage your inventory.
            </h6>

            <a-checkbox class="mt-4" v-model="doNotShowTMInfoTipAgain">
                Don’t show me this message again</a-checkbox
            >
        </a-modal>
    </div>
</template>

<script>
import UnitCard from "@/components/inventory/UnitCard";
import StackCard from "@/components/inventory/StackCard";
import EmptyApp from "bh-mod/components/common/EmptyApp";
import MoreActions from "bh-mod/components/common/MoreActions";
import BulkTags from "@/components/inventory/BulkTags";
import BulkStatus from "@/components/inventory/BulkStatus";
import BhLoading from 'bh-mod/components/common/Loading'
import initSp from "../../condogrid.js"

export default {
    components: {
        UnitCard,
		StackCard,
        EmptyApp,
        MoreActions,
        BulkTags,
		BulkStatus,
		BhLoading
    },
    computed: {
        overviewCount() {
            return this.$store.getters.getCount;
        },
        filter() {
            return this.$store.state.inventory.highriseFilter;
        },
		currentView(){
			return this.$store.state.inventory.currentView
		},
        instance() {
            return this.$store.state.instance;
        },
		user() {
			return this.$store.state.user.user
		},
		siteplan(){
			return this.$store.state.inventory.siteplan
		},
        storeUnits() {
            return this.$store.state.inventory.units;
        },
        units() {
			console.log('HIGHRISE UNITS: ', Object.values(this.$store.state.inventory.units));
            const orderAlpha = (array) => {
                array = array.sort((a, b) => {
					let aa = parseInt(a.unitNumber) || a.unitNumber;
					let bb = parseInt(b.unitNumber) || b.unitNumber;
                    return aa < bb ? -1 : aa > bb ? 1 : 0;
                });
                return array;
            };
            let units = {};
            let data = this.$store.state.inventory.highriseFilter || { assignee: ["any"], type: ["any"], price: [0, 0], sqft: [0, 0], beds: ["any"], baths: "any", floor: "any", exposure: ["any"], exterior: ["any"], tags: ["any"] };
            let statuses = this.selectType;
            Object.values(this.$store.state.inventory.units).forEach((unit) => {
                if (unit.buildingType == "condounits") {
                    units[unit.id] = unit;
                }
            });
            if (
                statuses[0] == "total" &&
                data.type && data.type[0] == "any" &&
                data.price && data.price[1] == 0 &&
                data.sqft && data.sqft[1] == 0 &&
                data.beds && data.beds[0] == "any" &&
                data.baths == "any" &&
                data.floor == "any" &&
                data.exposure && data.exposure[0] == "any" &&
                data.assignee && data.assignee[0] == "any" &&
                data.exterior && data.exterior[0] == "any" &&
                data.tags && data.tags[0] == "any"
            ) {
                return orderAlpha(Object.values(units));
            }

            const checkPrice = (unit, prices) => {
                if (prices[1] == 0) return true;

                let list = [];

                Object.keys(this.unitsByPrice.priceMap).forEach((price) => {
                    if (price >= prices[0] && price <= prices[1]) {
                        list = list.concat(this.unitsByPrice.priceMap[price]);
                    }
                });

                if (list.includes(unit.id)) return true;
                else return false;
            };

            const checkSqft = (unit, sqft) => {
                if (sqft[1] == 0) return true;

                let list = [];

                Object.keys(this.unitsBySqft.sqftMap).forEach((sqFt) => {
                    if (sqFt >= sqft[0] && sqFt <= sqft[1]) {
                        list = list.concat(this.unitsBySqft.sqftMap[sqFt]);
                    }
                });

                if (list.includes(unit.id)) return true;
                else return false;
            };

            const checkStatus = (unit, status) => {
                if (unit.status == null) unit.status = "draft";
                if (status[0] === "total") return true;
                if (status.includes("available")) {
                    return (
                        status.includes(unit.salesStatus) ||
                        unit.salesStatus == "inventory"
                    );
                }
                return (
                    status.includes(unit.salesStatus) ||
                    status.includes(unit.status)
                );
            };

            const checkBedType = (unit, beds) => {
                if (beds[0] == "any") return true;

                let list = [];

                beds.forEach((bedType) => {
                    if (this.unitsByBedType[bedType]) {
                        list = list.concat(this.unitsByBedType[bedType]);
                    }
                });

                if (list.includes(unit.id)) return true;
                else return false;
            };

            const checkBath = (unit, baths) => {
                if (baths == "any") return true;
                let pass = true;

                if (
                    unit.packages &&
                    unit.packages.length &&
                    unit.packages[0].baths &&
                    unit.packages[0].baths == baths
                )
                    return pass;
                else return false;
            };

            const checkExposure = (unit, exposure) => {
                if (exposure[0] == "any") return true;

                let pass = true;
                if (
                    unit.packages &&
                    unit.packages.length &&
                    (unit.packages[0].exposure || 'none') &&
                    exposure.includes(unit.packages[0].exposure || 'none')
                )
                    return pass;
                else return false;
            };

			const checkAssignee = (unit, assignee) => {
				if(!assignee) return false;
                if (assignee[0] == "any") return true;

                let pass = true;
				if (assignee.includes("none") && (!unit.allocatedTo || !unit.allocatedTo.id)){
					return pass;
				} else if (
                    unit.allocatedTo &&
                    unit.allocatedTo.id &&
                    assignee.includes(unit.allocatedTo.id)
                )
                    return pass;
                else return false;
            };

            const checkExterior = (unit, exterior) => {
                if (exterior[0] == "any") return true;

                let pass = true;

                if (
                    exterior.includes("penthouse") &&
                    unit.packages &&
                    unit.packages.length &&
                    unit.packages[0].hasPenthouse
                )
                    return pass;
                if (
                    exterior.includes("den") &&
                    unit.packages &&
                    unit.packages.length &&
                    unit.packages[0].hasDen
                )
                    return pass;
                if (
                    exterior.includes("terrace") &&
                    unit.packages &&
                    unit.packages.length &&
                    unit.packages[0].hasTerrace
                )
                    return pass;

                return false;
            };

            const checkFloor = (unit, floor) => {
                if (floor == "any") return true;

                let pass = true;

                let id = "";

                if (unit.unitGroup) id = unit.unitGroup;
                if (unit.unitGroup.id) id = unit.unitGroup.id;

                if (
                    floor == id &&
                    unit.packages &&
                    unit.packages.length &&
                    !unit.packages[0].hasCondo
                )
                    return pass;
                return false;
            };

            const checkTags = (unit, tags) => {
                if (tags[0] === "any") return true;

                const isFound = (arr1, arr2) => {
                    let found = false;
                    arr1.forEach((str) => {
                        if (arr2.includes(str)) found = true;
                    });
                    return found;
                };

                let pass = true;
                if (!isFound(unit.tags, tags)) pass = false;
                return pass;
            };

            return orderAlpha(
                Object.values(this.$store.state.inventory.units).filter(
                    (unit) => {
						if(unit.buildingType !== "condounits") return false;
                        if (!checkPrice(unit, data.price)) return false;
                        if (!checkSqft(unit, data.sqft)) return false;
                        if (!checkStatus(unit, statuses)) return false;
                        if (!checkBedType(unit, data.beds)) return false;
                        if (!checkBath(unit, data.baths)) return false;
                        if (!checkExposure(unit, data.exposure)) return false;
                        if (!checkAssignee(unit, data.assignee)) return false;
                        if (!checkExterior(unit, data.exterior)) return false;
                        if (!checkFloor(unit, data.floor)) return false;
                        if (!checkTags(unit, data.tags)) return false;
                        return true;
                    }
                )
            );
        },
        unitsByBedType() {
            return this.$store.getters.highRiseOptions.map;
        },
        unitsByPrice() {
            return this.$store.getters.unitsByPrice;
        },
        unitsBySqft() {
            return this.$store.getters.unitsBySqft;
        },
        unitGroups() {
            let unitGroups = {};
            Object.values(this.$store.state.inventory.unitGroups).forEach(
                (uG) => {
                    if (uG.buildingType == "condounits") {
                        unitGroups[uG.id] = uG;
                    }
                }
            );
            return unitGroups;
        },
        users() {
            return this.$store.state.inventory.users;
        },

        userAppOptions() {
            return (
                (this.$store.state.inventory.allSettings &&
                    this.$store.state.inventory.allSettings.userApp &&
                    this.$store.state.inventory.allSettings.userApp.options) ||
                {}
            );
        },

        appAddOns() {
            return (
                (this.$store.state.inventory.allSettings &&
                    this.$store.state.inventory.allSettings.app &&
                    this.$store.state.inventory.allSettings.app.options &&
                    this.$store.state.inventory.allSettings.app.options
                        .addons) ||
                {}
            );
        },

        packageAddOns() {
            return (
                (this.modal.object &&
                    this.modal.object.packages &&
                    this.modal.object.packages[0] &&
                    this.modal.object.packages[0].other &&
                    this.modal.object.packages[0].other.addons) || {
                    parking: [],
                    lockers: [],
                    bikeRacks: [],
                }
            );
        },

        addOns() {
            const pkgAddOns = {
                parking: [],
                lockers: [],
                bikeRacks: [],
            };
            const generalAddOns = {
                parking: [],
                lockers: [],
                bikeRacks: [],
            };

            if (this.appAddOns.parking && this.appAddOns.parking.length) {
                generalAddOns.parking = this.appAddOns.parking.filter(
                    (p) => !p.unitId
                );
            }

            if (this.appAddOns.lockers && this.appAddOns.lockers.length) {
                generalAddOns.lockers = this.appAddOns.lockers.filter(
                    (l) => !l.unitId
                );
            }

            if (this.appAddOns.bikeRacks && this.appAddOns.bikeRacks.length) {
                generalAddOns.bikeRacks = this.appAddOns.bikeRacks.filter(
                    (b) => !b.unitId
                );
            }

            if (
                this.packageAddOns.parking &&
                this.packageAddOns.parking.length
            ) {
                pkgAddOns.parking = this.packageAddOns.parking.map((p) => {
                    return {
                        ...p,
                        disabled: true,
                    };
                });
            }

            if (
                this.packageAddOns.lockers &&
                this.packageAddOns.lockers.length
            ) {
                pkgAddOns.lockers = this.packageAddOns.lockers.map((l) => {
                    return {
                        ...l,
                        disabled: true,
                    };
                });
            }

            if (
                this.packageAddOns.bikeRacks &&
                this.packageAddOns.bikeRacks.length
            ) {
                pkgAddOns.bikeRacks = this.packageAddOns.bikeRacks.map((b) => {
                    return {
                        ...b,
                        disabled: true,
                    };
                });
            }

            return {
                parking: [...generalAddOns.parking, ...pkgAddOns.parking],
                lockers: [...generalAddOns.lockers, ...pkgAddOns.lockers],
                bikeRacks: [...generalAddOns.bikeRacks, ...pkgAddOns.bikeRacks],
            };
        },

		unitOverview() {
			let data = [
				{
					label: "Total Units",
					value: "total",
					color: "#2B93C6",
					select: "Select All",
				},
				{
					label: "Units Available",
					value: "available",
					color: "#1EC48C",
					select: "Available",
				},
				{
					label: "Units Sold",
					value: "sold",
					color: "#FD6C83",
					select: "Sold",
				},
				{
					label: "Units On Hold",
					value: "hold",
					color: "#FECE5B",
					select: "Hold",
				},
				{
					label: "Sold Conditional",
					value: "conditional",
					color: "#9693E8",
					select: "Sold Conditional",
				},
				{
					label: "Not Released",
					value: "notreleased",
					color: "#3F3356",
					select: "Not Released",
				}
			]

			if (this.instance.package && this.instance.package.itemCode === "PA-CON-HRP") {
				data.push(...[
					{
						label: "Units Allocated",
						value: "allocated",
						color: "var(--primary)",
						select: "Allocated",
					},
					{
						label: "Units Approved",
						value: "approved_for_reservation",
						color: "#134F5C",
						select: "Approved",
					},
					{
						label: "Units Reserved",
						value: "reserved",
						color: "#419E00",
						select: "Reserved",
					}
				])
			}

			return data
		},

		statusOptions() {
			let options = [
				{ value: "available", label: "Available" },
				{ value: "sold", label: "Sold" },
				{ value: "hold", label: "Hold" },
				{ value: "conditional", label: "Conditional" },
				{ value: "inventory", label: "Inventory" },
				{ value: "notreleased", label: "Not Released" },
			]

			if (this.instance.package && this.instance.package.itemCode === "PA-CON-HRP") {
				options.push(...[
					{ value: "allocated", label: "Allocated" },
					{ value: "approved_for_reservation", label: "Approved" },
					{ value: "reserved", label: "Reserved" },
				])
			}

			return options;
		}
    },
    data() {
        return {
			displayType:'none',
            showTMInfoTip: false,
            doNotShowTMInfoTipAgain: false,
            selectedUpdateStatusUnit: {},
            showBulkTags: false,
			showBulkStatus: false,
            bulkActions: [
                {
                    label: "Bulk Apply Tags",
                    value: "tag",
                },
				{
					label: "Bulk Update Status",
					value: "status",
				},
            ],
            selectUnit: [],
            status: {
                status: "",
                price: 0,
                owner: "",
                purchasers: [],
                addons: {
                    parking: [],
                    lockers: [],
                    bikeRacks: [],
                },
            },
			loading:false,
			loadedSiteplan:false,
            selectType: ["total"],
            modal: {
                visible: false,
                type: "",
                object: {},
            },
            currentTab: "parking",
			currentWidth: window.innerWidth,
			salesStatus: {
				available: "Available",
				sold: "Sold",
				hold: "Hold",
				conditional: "Sold Conditional",
				inventory: "Standing Inventory",
				notreleased: "Not Released",
				allocated: "Allocated",
				approved_for_reservation: "Approved",
				reserved: "Reserved"
			},
			statusColorCode: {
				available: "#1EC48C",
				sold: "#FD6C83",
				hold: "#FECE5B",
				conditional: "#9693E8",
				notreleased: "#3F3356",
				allocated: "var(--primary)",
				approved_for_reservation: "#134F5C",
				reserved: "#419E00"
			}
        };
    },
    created() {
		this.$store.commit('UPDATE_INVENTORY_VIEW', "grid")
        if (
            this.userAppOptions &&
            this.userAppOptions.doNotShowTMInfoTipAgain
        ) {
            this.doNotShowTMInfoTipAgain = true;
        }
    },

    methods: {
        req: (msg) => ({ required: true, message: msg }),
		updateDisplayType(type){
			if (type == this.displayType){
				this.displayType = 'none';
			} else if (type != ''){
				this.displayType = type;
			} else {
				this.displayType = 'none';
			}
		},
		async onEvent(action,data){
			console.log('SHOWING LOT', data)
			if (action === 'SHOW_LOT' && data && data.units && data.units.length){
				this.$store.commit("OPEN_UNIT_DETAILS", data.units[0]);
			} else if (action === 'TAP'){
				console.log('JUST TAP')
			}
		},
		zoom(is){
			if (!is) return
			if (is === 'out') {
				let zoom = this.mysp.camera.zoom - 0.4
				if (zoom <= 1.04 ) this.mysp.zoomReset()
				else this.mysp.zoomOut()
			}
			if (is === 'in') this.mysp.zoomIn()
		},
        applyBulkTags(tags) {
            this.units.forEach((x) => {
                if (this.selectUnit.includes(x.id) && (!x.transactionId || x.salesStatus === "available")) {
                    tags.forEach((y) => {
                        if (!x.tags.includes(y)) {
                            x.tags.push(y);
                        }
                    });
                }
            });
        },
		applyBulkStatus(status) {
            this.units.forEach((x) => {
                if (this.selectUnit.includes(x.id) && (!x.transactionId || x.salesStatus === "available" || x.salesStatus === 'notreleased')) {
                    x.salesStatus = status;
                }
            });
        },
        closeBulkTags() {
            this.showBulkTags = false;
        },
		closeBulkStatus() {
            this.showBulkStatus = false;
        },
        bulkActioned({ key }) {
            if (key == "tag") {
                this.showBulkTags = true;
            }
			if (key == "status") {
				this.showBulkStatus = true;
			}
        },
        openFilter() {
            this.$store.commit("OPEN_FILTER");
        },
        changeSelect(type, e) {
            if (this.selectType[0] == "total") this.selectType = [];
            if (type != "total") {
                if (this.selectType.includes(type)) {
                    let index = this.selectType.findIndex((x) => x == type);
                    this.selectType.splice(index, 1);
                } else {
                    this.selectType.push(type);
                }
            } else {
                this.selectType = ["total"];
            }
            if (this.selectType.length == 0 || this.selectType.length == 8)
                this.selectType = ["total"];
        },
		async onViewChange(view){
			console.log('Changing view...', view)
			if (this.currentView != 'map' && view == 'map' && !this.loadedSiteplan){
				console.log('Map View')
				this.$store.commit("LOAD", true);
				console.log('Loading condogrid...')
				let dom = document.querySelector('.siteplan-holder')
				this.mysp = initSp({
					dom,
					spData:this.siteplan,
					onEvent: this.onEvent,
					hideHold:false,
					$userType: this.$userType,
					currentUser: this.user
				})

				let initObj = {
					instance:this.instance.id,
				}
				await this.mysp.init(initObj)

				window.mysp = this.mysp
				this.loadedSiteplan = true
				this.$store.commit("LOAD", false);
			}
			this.$store.commit("UPDATE_INVENTORY_VIEW", view)
		},
        updateTags(unit, tags) {
			this.$store.commit("LOAD", true);
            this.$api
				.put(`/units/:instance/${unit.id}`, { tags, buildingType: 'condounits' })
				.then(({ data }) => {
					this.$store.commit("SET_PROP", {
                        where: ["units", data.id],
                        what: data,
                    });
					this.$message.success("Tags updated successfully!");
				})
				.catch((err) => {
					this.$message.error(
						"An error occurred. Please try again"
					);
					console.error(err);
				})
				.finally(() => {
					this.$store.commit("LOAD", false);
				});
		},
        makeTransaction(unit) {
			window.open(`https://transactions.bildhive.${this.$tld}/new?floor=${unit.unitGroup && unit.unitGroup.id}&unit=${unit.id}`, '_blank');
        },
		createWorksheet(unit) {
			window.open(`https://worksheets.bildhive.${this.$tld}/create?unit=${unit.id}`, '_blank');
        },
        toggleSelect(id) {
            if (this.selectUnit.includes(id)) {
                let index = this.selectUnit.findIndex((x) => x == id);
                this.selectUnit.splice(index, 1);
            } else {
                this.selectUnit.push(id);
            }
        },
        countStatus(status, from) {
            let units = [];
            if (from == "all") {
                Object.values(this.units).forEach((unit) => {
                    if (unit.salesStatus == status) {
                        units.push(unit);
                    }
                });
            } else {
                Object.values(this.units).forEach((unit) => {
                    if (
                        unit.salesStatus == status &&
                        unit.unitGroup.id == from
                    ) {
                        units.push(unit);
                    }
                });
            }
            return units;
        },
        selectFloor(id) {
            this.$router.push(`/details/${id}`);
            let crumbs = JSON.parse(
                JSON.stringify(this.$store.state.inventory.crumbs)
            );
            if (
                crumbs[0] == "All Inventory" &&
                this.instance.productType == "highrise"
            ) {
                crumbs.push(this.unitGroups[id].name);
            }
            this.$store.commit("UPDATE_CRUMBS", crumbs);
        },
        unsellUnit(id) {
            let self = this;
            this.$confirm({
                title: "Unsell Unit",
                content: (h) => <div>Do you want to unsell this unit?</div>,
                okText: "Unsell",
                okType: "danger",
                cancelText: "Cancel",
                centered: true,
                onOk() {
                    let object = {
                        unit: id,
                        status: "available",
                    };
                    self.$api
                        .put(`/sales/:instance/hr`, object)
                        .then(({ data }) => {
                            self.$store.commit("UPDATE_UNITS", data);
                            self.onClose();
                        })
                        .catch((err) => {
                            self.$message.error(
                                "An error occurred. Please try again"
                            );
                            console.error(err);
                        });
                },
                onCancel() {
                    console.log("Cancel");
                },
            });
        },
        changeStatus(e) {
            this.status = {
                status: e,
                price:
                    (this.modal.object.packages.length &&
                        this.modal.object.packages[0].price) ||
                    0,
                owner: "",
                purchasers: [],
                addons: this.status.addons,
            };
        },
        openModal(id, type, uG) {
            this.applyingTags = [];

            let unit = JSON.parse(JSON.stringify(this.storeUnits[id]));

            this.$store.commit("LOAD", true);

            this.$api
                .get(`/unit-groups/:instance`)
                .then(({ data }) => {
                    let foundUG = data.find((x) => x.id == uG);
                    let foundUnit = null;
                    if (foundUG) {
                        foundUnit = foundUG.units.find((x) => x.id == id);
                    }

                    if (foundUnit) {
                        if (unit.salesStatus !== foundUnit.salesStatus) {
                            this.$store.commit("SET_PROP", {
                                where: ["units", foundUnit.id],
                                what: foundUnit,
                            });

                            let message = `Unit ${foundUnit.unitNumber} has been updated externally!`;
                            if (foundUnit.salesStatus === "sold")
                                message = `Unit ${foundUnit.unitNumber} has been sold already!`;

                            return this.$notification.error({
                                message,
                                description:
                                    "This unit was externally updated by another user. Please review and try again.",
                            });
                        }

                        unit = JSON.parse(JSON.stringify(foundUnit));

                        this.modal.type = type;
                        this.modal.object = unit;
                        this.status.status = unit.salesStatus;
                        this.modal.visible = true;
                        this.selectedUpdateStatusUnit = {};

                        if (
                            this.packageAddOns.parking &&
                            this.packageAddOns.parking.length
                        ) {
                            this.status.addons.parking =
                                this.packageAddOns.parking.map((p) => p.id);
                        }
                        if (
                            this.packageAddOns.lockers &&
                            this.packageAddOns.lockers.length
                        ) {
                            this.status.addons.lockers =
                                this.packageAddOns.lockers.map((l) => l.id);
                        }
                        if (
                            this.packageAddOns.bikeRacks &&
                            this.packageAddOns.bikeRacks.length
                        ) {
                            this.status.addons.bikeRacks =
                                this.packageAddOns.bikeRacks.map((b) => b.id);
                        }
                    } else {
                        this.$message.error(
                            "Unit not found! Please review and try again."
                        );
                    }
                })
                .catch((err) => {
                    this.$message.error("An error occurred. Please try again");
                    console.error(err);
                })
                .finally(() => {
                    this.$store.commit("LOAD", false);
                });
        },
        submit() {
            if (this.modal.type == "updateStatus") {
                this.$refs.status.validate((valid) => {
                    if (valid) {
                        let object = {};
                        if (
                            this.status.status == "sold" ||
                            this.status.status == "conditional"
                        ) {
                            object = {
                                unit: this.modal.object.id,
                                finalPrice: this.status.price,
                                status: this.status.status,
                                soldBy: this.status.owner,
                                purchasers: this.status.purchasers.map((p) => {
                                    return { fullName: p };
                                }),
                                addons: {
                                    parking: this.status.addons.parking.map(
                                        (p) => {
                                            const find =
                                                this.addOns.parking.find(
                                                    (pp) => pp.id === p
                                                );
                                            delete find.disabled;
                                            return {
                                                ...find,
                                                unitId: this.modal.object.id,
                                            };
                                        }
                                    ),
                                    lockers: this.status.addons.lockers.map(
                                        (l) => {
                                            const find =
                                                this.addOns.lockers.find(
                                                    (ll) => ll.id === l
                                                );
                                            delete find.disabled;
                                            return {
                                                ...find,
                                                unitId: this.modal.object.id,
                                            };
                                        }
                                    ),
                                    bikeRacks: this.status.addons.bikeRacks.map(
                                        (b) => {
                                            const find =
                                                this.addOns.bikeRacks.find(
                                                    (bb) => bb.id === b
                                                );
                                            delete find.disabled;
                                            return {
                                                ...find,
                                                unitId: this.modal.object.id,
                                            };
                                        }
                                    ),
                                },
                            };
                        } else if (
                            this.status.status == "available" ||
                            this.status.status == "inventory" ||
                            this.status.status == "hold" ||
                            this.status.status == "notreleased"
                        ) {
                            object = {
                                unit: this.modal.object.id,
                                status: this.status.status,
                            };
                        }
						this.$store.commit("LOAD", true);
                        this.$api
                            .put(`/sales/:instance/hr`, object)
                            .then(({ data }) => {
                                this.$store.commit("UPDATE_UNITS", data);
                                this.onClose();
                            })
                            .catch((err) => {
                                this.$message.error(
                                    "An error occurred. Please try again"
                                );
                                console.error(err);
                            })
                            .finally(() => {
                                this.$store.commit("LOAD", false);
                            });
                    }
                });
            }
        },
        onClose() {
            if (this.modal.type == "updateStatus")
                this.$refs.status.resetFields();
            this.modal.visible = false;
            this.modal.type = "";
            this.modal.object = {};
            this.status = {
                status: "",
                price: 0,
                owner: "",
                purchasers: [],
                addons: {
                    parking: [],
                    lockers: [],
                    bikeRacks: [],
                },
            };
        },

        updateStatus(unit) {
            if (
                (this.$p === 10 && unit.salesStatus != "notreleased") ||
                this.$p >= 20
            ) {
                // show TM user info modal
                if (!this.doNotShowTMInfoTipAgain) {
                    this.selectedUpdateStatusUnit = unit;
                    this.showTMInfoTip = true;
                } else {
                    this.openModal(unit.id, "updateStatus", unit.unitGroup.id);
                }
            } else
                return this.$message.error(
                    "You do not have permission to update the status"
                );
        },

        tmInfoUpdate() {
            this.showTMInfoTip = false;
            if (this.doNotShowTMInfoTipAgain) {
                const options = {
                    seenIntro: true,
                    ...this.userAppOptions,
                    doNotShowTMInfoTipAgain: this.doNotShowTMInfoTipAgain,
                };
                this.$api.post(`/settings/:instance/inventory/user`, {
                    options,
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				});
                const allSettings = this.$store.state.inventory.allSettings;
				if (allSettings.userApp === null) {
					allSettings.userApp = {
						options: options
					};
				} else {
					allSettings.userApp.options = options;
				}
                this.$store.commit("SET_SETTINGS", allSettings);
            }
            if (this.selectedUpdateStatusUnit !== {}) {
                this.openModal(
                    this.selectedUpdateStatusUnit.id,
                    "updateStatus",
                    this.selectedUpdateStatusUnit.unitGroup.id
                );
            }
        },

        filterSimilarUnits(unit) {
            const pkg = unit.packages[0];
            let {
                beds = false,
                hasDen,
                hasCondo,
                hasRoof,
                hasTerrace,
                hasPenthouse,
            } = pkg;
            const bedOptions = [];
            const exteriors = [];

            if (hasCondo) {
                if (!bedOptions.includes("Condotowns"))
                    bedOptions.push("Condotowns");
            }
            if (hasRoof) {
                if (!bedOptions.includes("Roof Top"))
                    bedOptions.push("Roof Top");
            }
            if (hasTerrace) {
                if (!bedOptions.includes("Terrace Suites"))
                    bedOptions.push("Terrace Suites");
                if (!exteriors.includes("terrace")) exteriors.push("terrace");
            }
            if (hasPenthouse) {
                if (!bedOptions.includes("Penthouse Suites"))
                    bedOptions.push("Penthouse Suites");
                if (!exteriors.includes("penthouse"))
                    exteriors.push("penthouse");
            }
            if (beds) {
                let bedCat = {
                    label: `${beds}${hasDen ? "+1" : ""} Bedrooms`,
                    value: `${beds}${hasDen ? "+1" : ""}`,
                };
                if (hasDen && !exteriors.includes("den")) exteriors.push("den");
                if (!bedOptions.includes(bedCat.value))
                    bedOptions.push(bedCat.value);
            }

            const filter = JSON.parse(
                JSON.stringify(this.$store.state.inventory.highriseFilter)
            );

            filter.price = [0, pkg.price];
            filter.sqft = [0, pkg.sqft];
            filter.beds = (bedOptions.length && bedOptions) || ["any"];
            filter.baths = pkg.baths || "any";
            filter.floor = (unit.unitGroup && unit.unitGroup.id) || "any";
            filter.exposure = [pkg.exposure || "any"];
            filter.assignee = [ (unit.allocatedTo && unit.allocatedTo.id) || "any"];
            filter.exterior = (exteriors.length && exteriors) || ["any"];
            filter.tags = (unit.tags.length && unit.tags) || ["any"];

            this.$store.commit("OPEN_FILTER", filter);
        },

        changeTab(tab) {
            if (tab == 1) this.currentTab = "parking";
            if (tab == 2) this.currentTab = "lockers";
            if (tab == 3) this.currentTab = "bikeRacks";
        },
		updateWidth(){
			this.currentWidth = window.innerWidth;
		}
    },
	mounted() {
		window.addEventListener('resize', this.updateWidth)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateWidth)
	},
};
</script>

<style scoped lang="scss">
.stack-row:not(:first-child) {
	margin-top: 0.5em;
}
.stack-card:not(:first-child) {
	margin-left: 0.5rem;
}
.row-scroll::-webkit-scrollbar {
    height: 4px;
}
.row-scroll::-webkit-scrollbar-track {
    background: var(--light-divider);
}
.row-scroll::-webkit-scrollbar-thumb {
    background: var(--gray);
    border-radius: 10px;
}
.row-scroll::-webkit-scrollbar-thumb:hover {
    background: var(--orange);
}

.overview-card {
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #e6eaee;
    background-color: #fff;
}
@media screen and (max-width: 750px) {
    .last-item {
        grid-column: 1 / span 2;
    }
}


.grid-display-2 {
    display: grid;
    gap: 1rem;
    @media screen and (min-width: 1100px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (max-width: 1100px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.gap-10 {
    gap: 2.5rem;
}
.gap-5 {
    gap: 1.25rem;
}




// Condo grid styles

.siteplan-tooltip-style1{
    pointer-events: none;

}
.siteplan-tooltip-style1 div{
    padding: .25rem .5rem;
    border-radius:.75rem;
    font-size:12px;
    position: relative;
    top:-15px;
	color: #FFF;
	border: 2px solid white;
}
.filter-dropdown {
    border: 1px solid #aaa;
    padding: 10px;
    background: #eee;
    box-shadow: 0 2px 5px rgba(100,100,100,0.3);
}
.filter-dropdown-expanded {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: 1rem;
	border: 1px solid #aaa;
    padding: 10px;
    background: #eee;
    box-shadow: 0 2px 5px rgba(100,100,100,0.3);
}
.detail-package {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	gap: 1rem;
}
.detail-premiums {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 2rem;
}
@media screen and (min-width: 768px) {
	.detail-package {
		grid-template-columns: repeat(5, minmax(0, 1fr));
		gap: 1.5rem;
	}
}
@media screen and (min-width: 1024px) {
	.detail-package {
		grid-template-columns: repeat(7, minmax(0, 1fr));
		gap: 2rem;
	}
	.detail-premiums {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

.filter-item {
	border-right: 1px solid #D0C9D6;
	height: 100%;
}
.filter-price {
	width: 100%;
	border: 0;
	padding: 0;
	margin: 0;
}
.price-label {
	font-size: 12px;
}
.price-field {
	position: relative;
	width: 100%;
	box-sizing: border-box;
	background: rgba(248, 247, 244, 0.2);
	border-radius: 3px;
}
.price-field input[type=range] {
	position: absolute;
}
.dropdown-filter {
	width: 100%;
	border: 0 !important;
	-webkit-appearance: none;
	-moz-appearance: none;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
}
.dropdown-filter:focus-visible {
	outline: none;
}

.details-mask-overlay {
	background: rgba(0,0,0,0.6);
	z-index: 100;
	overflow: hidden;
}
.details-page {
	background-color: white;
	z-index: 101;
	overflow-y: scroll;
	padding: 5%;
}
.isActive {
	display: flex !important;
}
.notActive {
	display: none !important;
}


/* Reset style for input range */
.price-field input[type=range] {
	width: 100%;
	height: 2px;
	border: 0;
	outline: 0;
	box-sizing: border-box;
	border-radius: 5px;
	pointer-events: none;
	-webkit-appearance: none;
}
.price-field input[type=range]::-webkit-slider-thumb {
	-webkit-appearance: none;
}
.price-field input[type=range]:active,
.price-field input[type=range]:focus {
	outline: 0;
}
.price-field input[type=range]::-ms-track {
	width: 100%;
	height: 2px;
	border: 0;
	outline: 0;
	box-sizing: border-box;
	border-radius: 5px;
	pointer-events: none;
	background: transparent;
	border-color: transparent;
	color: transparent;
	border-radius: 5px;
}


/* Style toddler input range */
.price-field input[type=range]::-webkit-slider-thumb {
/* WebKit/Blink */
	position: relative;
	-webkit-appearance: none;
	margin: 0;
	border: 0;
	outline: 0;
	border-radius: 50%;
	height: 12px;
	width: 12px;
	margin-top: -4px;
	background-color: #000;
	cursor: pointer;
	pointer-events: all;
	z-index: 10;
}
.price-field input[type=range]::-moz-range-thumb {
	/* Firefox */
	position: relative;
	appearance: none;
	margin: 0;
	border: 0;
	outline: 0;
	border-radius: 50%;
	height: 12px;
	width: 12px;
	margin-top: -5px;
	background-color: #000;
	cursor: pointer;
	pointer-events: all;
	z-index: 100;
}
.price-field input[type=range]::-ms-thumb  {
	/* IE */
	position: relative;
	appearance: none;
	margin: 0;
	border: 0;
	outline: 0;
	border-radius: 50%;
	height: 12px;
	width: 12px;
	margin-top: -5px;
	background-color: #000;
	cursor: pointer;
	pointer-events: all;
	z-index: 100;
}


/* Style track input range */
.price-field input[type=range]::-webkit-slider-runnable-track {
	/* WebKit/Blink */
	width: 100%;
	height: 4px;
	cursor: pointer;
	background: var(--orange);
	border-radius: 5px;
}
.price-field input[type=range]::-moz-range-track {
	/* Firefox */
	width: 100%;
	height: 4px;
	cursor: pointer;
	background: var(--orange);
	border-radius: 5px;
}
.price-field input[type=range]::-ms-track {
	/* IE */
	width: 100%;
	height: 4px;
	cursor: pointer;
	background: var(--orange);
	border-radius: 5px;
}


/* Style for active state input */
.price-field input[type=range]:hover::-webkit-slider-thumb {
	box-shadow: 0 0 0 0.5px #fff;
	transition-duration: 0.3s;
}
.price-field input[type=range]:active::-webkit-slider-thumb {
	box-shadow: 0 0 0 0.5px #fff;
	transition-duration: 0.3s;
}
</style>

<style>
.floor-card {
    border: 1px solid #9EA0A5;
}
.floor-card.active {
    border: 1px solid #F79425 !important;
    color: #F79425 !important;
}
</style>
