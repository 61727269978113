var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full dF fC f1 px-4 pb-4",staticStyle:{"overflow-y":"scroll"}},[_c('FilterDrawer'),_c('HomeDetails'),_c('ModelDetails'),(
            _vm.instance.productType == 'lowrise' &&
            Object.values(_vm.lots).length == 0
        )?_c('EmptyApp',{attrs:{"text":'Configure lots in Site Plan Configurator to manage inventory',"img":'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'}}):_vm._e(),(
            _vm.instance.productType == 'highrise' &&
            Object.values(_vm.units).length == 0
        )?_c('EmptyApp',{attrs:{"text":'You don\'t have any units',"img":'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'}}):_vm._e(),(
            _vm.instance.productType == 'highrise' &&
            Object.values(_vm.units).length != 0
        )?_c('HighriseInventory',{key:_vm.$store.state.inventory.newTime}):_vm._e(),(
            _vm.instance.productType == 'lowrise' &&
            Object.values(_vm.lots).length != 0
        )?_c('LowriseInventory',{key:_vm.$store.state.inventory.newTime}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }