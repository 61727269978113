import { reactive,computed, } from "vue";
import { createPopper } from './popper.js';

const siteplanApp = ({dom,options,spData,initCallback,onEvent,hideHold, $userType, currentUser = {}}) => {

    const drawLot = {
		// apiURL:`http://localhost:1337`,
		apiURL:`${window.location.href.includes("com") ? 'https://api.bildhive.com' : 'https://api.bildhive.dev'}`,
		hideColor:'rgba(200,200,200,1)',
		highlightShapes:[],
		dotFraction:4,
		colorLookup:{
			available:'#1ec48c',
			inventory:'#3395c6',
			sold:'red',
			soldConditional:'#9693e8',
			conditional:'#9693e8',
			hold:'#fece5b',
			notreleased:'#3f3356',
			allocated:'#f7941e',
			approved_for_reservation:'#134f5c',
			reserved:'#419E00'
		},
		statusNames:{
			available:'Available',
			inventory:'Inventory',
			sold:'Sold',
			conditional:'Conditional',
			soldConditional:'Sold conditional',
			hold:'Hold',
			notreleased:'Not released',
			allocated:'Allocated',
			approved_for_reservation:'Approved for reservation',
			reserved:'Reserved'
		},
		anim:{
			speed:'medium',
			amount:0.5,
			leader:'',
			shapes:{},
		},
		iSlugs:{},
		developerInfo:{},
		spURL:'',
		touchInit:0,
		showTags:[],
		socketIds:{},
		instance:'',
		sockets:{},
		plans:{},
		updatedLots:{},
		lots:{},
		instances:{},
		mobileMode:false,
		siteplanOverlay:null,
		hasError:false,
		filterMinPrice:0,
		filterMaxPrice:1,
		filterBeds:'all',
		filterDen:'all',
		filterBaths:'all',
		filterSqft:'all',
		filterExposure:'all',
		filteredShapes:[],
		showLots:true,
		showAmenity:true,
		mouseWheelZoom:false,
		mouseWheelAssist:13,
		zooming:false,
		siteplan:{},
		parentElement:null,
		img: null,
		imgUrl: null,
		shape: null,
		dotRadius: 0,
		shapes: null,
		width: null,
		height: null,
		canvas: null,
		ctx: null,
		mouseX:null,
		mouseY:null,
		mouseOver:null,
		isMouseDown:false,
		isMouseUp:true,
		initialPinchDistance:null,
		mouseDownShape:null,
		isMousePan:false,
		siteplanImage: new Image,
		lotImage: null,
		siteplanDrawn:false,
		camera:{nextZoom:1,zoom:1,x:0,y:0,},
		originalCamera:{x:0,y:0,},
		dragStart:{x:0,y:0,},
		MAX_ZOOM:5,
		MIN_ZOOM:1,
		SCROLL_SENSITIVITY:0.0005,
		moveY:0,
		moveX:0,
		boundaries:{
			top:0,
			bottom:0,
		},
		landscape:false,
		pan:{
			left:false,
			up:false,
			down:false,
			right:false,
			lastX:0,
			lastY:0,
		},
		updated:{
			it:0,
			ib:0,
			iw:0,
			ih:0,
		},
		performedAction:false,
		timerId:null,
		isImage(url) {
			return new Promise( (res,rej) => {
				let image = new Image()
				console.log('NEW IMAGE', image)
				image.onload = e => {
				  console.log('EEEEE',e)
				  res(true)
				}
				image.onerror = e => {
					console.log('errerer',e)
					 rej(false)
				}
				image.src = url

			})
		},
		debounceFunction:(event,func, delay) => {
			// Cancels the setTimeout method execution
			clearTimeout(drawLot.timerId)

			// Executes the func after delay time.
			drawLot.timerId  =  setTimeout(() => func(event), delay)
		},
		throttleFunction:(event,func, delay) => {
			if (drawLot.timerId) {
				return
			}

			// Schedule a setTimeout after delay seconds
			drawLot.timerId  =  setTimeout(function () {
				func(event)
				drawLot.timerId  =  undefined;
			}, delay)
		},
		analyzeLots(){
			this.lots
		},
		get_polygon_centroid: (pts,xKey = 'x', yKey = 'y') => {
			var first = pts[0], last = pts[pts.length-1];
			if (first[xKey] != last[xKey] || first[yKey] != last[yKey]) pts.push(first);

			var twicearea=0,
			x=0, y=0,
			nPts = pts.length,
			p1, p2, f;
			for ( var i=0, j=nPts-1 ; i<nPts ; j=i++ ) {
				p1 = pts[i]; p2 = pts[j];
				f = (p1[yKey] - first[yKey]) * (p2[xKey] - first[xKey]) - (p2[yKey] - first[yKey]) * (p1[xKey] - first[xKey]);
				twicearea += f;
				x += (p1[xKey] + p2[xKey] - 2 * first[xKey]) * f;
				y += (p1[yKey] + p2[yKey] - 2 * first[yKey]) * f;
			}
			f = twicearea * 3;
			return { x:x/f + first[xKey], y:y/f + first[yKey] };
		},
		reCoordinate(shape) {

			let { x, y, width, height } = shape

			let shapeY = this.p2({ y })
			let shapeX = this.p2({ x })
			shape.shapeX = shapeX + this.moveX
			shape.shapeY = shapeY + this.moveY
			shape.shapeW = this.p2({ x: width })
			shape.shapeH = this.p2({ y: height })
			shape.dotRadius = Math.min(shape.shapeW, shape.shapeH)

			this.dotRadius = Math.min(this.dotRadius, shape.dotRadius)

			// if (shape.shapeW < this.dotRadius) this.dotRadius = shape.shapeW
			// if (shape.shapeH < this.dotRadius) this.dotRadius = shape.shapeH



		},
		doPoints(shape){
			shape.points.forEach(point => {
				let {x,y}=point

				let pX = x / 100
				point.pX = (pX * shape.shapeW) + shape.shapeX

				let pY = y / 100
				point.pY = (pY * shape.shapeH) + shape.shapeY

			})

			var coordinates = shape.points,
				minx = coordinates[0].x,
				maxx = coordinates[0].x,
				miny = coordinates[0].y,
				maxy = coordinates[0].y;

			for (let index = 1; index < coordinates.length; index++) {
				if (coordinates[index].x < minx) minx = coordinates[index.x];
				if (coordinates[index].x > maxx) maxx = coordinates[index.x];
				if (coordinates[index].y < miny) miny = coordinates[index.y];
				if (coordinates[index].y > maxy) maxy = coordinates[index.y];
			}

			let result = this.get_polygon_centroid(shape.points,'pX','pY')
			let {x:cX = 0, y:cY = 0} = result
			shape.cX = cX
			shape.cX = cX
			shape.cY = cY
		},
		p2({ x = false, y = false }) {
			if (x) {
				let p = x / 100
				return p * this.imageWidth
			}
			if (y) {
				let p = y / 100
				return (p * this.imageHeight)
			}
		},
		plotLines(shape) {
			if (!shape.points) {
				this.hasError = true
			}

			let highlight = false
			if (this.highlightShapes.find(x => String(x) == String(shape.id))){
				highlight = true
			}


			let ctx = this.ctx

			ctx.lineWidth = "0.25";
			ctx.strokeStyle = 'rgba(0,0,0,1)'
			ctx.fillStyle = 'rgba(255,255,255,0)'

			//status color
			let colorLookup = this.colorLookup
			let status = shape.lot && shape.lot.units && shape.lot.units[0] && shape.lot.units[0].salesStatus
			let color = colorLookup[status] || 'rgba(255,255,255,0)'

			if (!this.hideStatus && this.hideDots && status != 'available'){
				ctx.fillStyle = color
			}

			if (shape.hidden || (shape.inactive || (shape.lot.status === 'hold' && this.hideHold))){
				ctx.fillStyle = this.hideColor
			}
			let x0 = 0
			let y0 = 0
			shape.points.forEach(({ x,pX,pY,y }, cI) => {

				if (cI) return ctx.lineTo(pX, pY)
				x0 = pX
				y0 = pY
				ctx.moveTo(pX, pY)
			})

			ctx.lineTo(x0, y0)

			ctx.closePath()
			if (!isNaN(this.mouseX) && !isNaN(this.mouseY) && !shape.hidden) {
				if (ctx.isPointInPath(this.mouseX, this.mouseY) && !shape.inactive){
					if (!this.isMousePan){
						this.mouseOver = shape
						ctx.lineWidth = "1.5";
						// ctx.fillStyle = 'rgba(255,255,255,0.5)'
						ctx.strokeStyle = 'rgba(255,255,255,1)'
					}
				}
			}
			ctx.fill()
			ctx.stroke();

			if (!(shape.hidden || (shape.inactive || (shape.lot.units && shape.lot.units.length && shape.lot.units[0].salesStatus === 'hold' && this.hideHold))) && !this.hideStatus && this.hideDots && status != 'available'){
				let text = `${shape.lot.units && shape.lot.units.length ? shape.lot.units[0].unitNumber : ''}`
				ctx.save(); //save canvas state
				ctx.fillStyle = "#FFF";
				console.log('Mobile Mode', this.mobileMode)
				if (this.mobileMode){
					ctx.font = `bold 0.35rem Arial`;
				} else {
					ctx.font = `bold 7px Arial`;
				}
				ctx.textAlign = "center";
				ctx.textBaseline = "middle";
				ctx.fillText(text, shape.cX, shape.cY);
				ctx.restore(); //restore canvas state
			}

			if (this.hideAvail && shape.lot.units && shape.lot.units.length && shape.lot.units[0].salesStatus === 'available') return
			if (shape.lot.units && shape.lot.units.length && shape.lot.units[0].salesStatus === 'hold' && this.hideHold) return
			if (!this.hideDots && !shape.hidden && !shape.inactive && shape.lot && shape.lot.units && shape.lot.units.length && shape.lot.units[0].salesStatus && shape.lot.type === 'lot'){

				let dotRadius = this.useLotRadius && shape.dotRadius ? shape.dotRadius : this.dotRadius

				ctx.beginPath();
				ctx.fillStyle = color
				ctx.lineWidth = "1";
				ctx.strokeStyle = 'white'

				// dotRadius:100, dotFraction:2
				let dotSize = dotRadius / this.dotFraction // 50
				let minDotSize = dotRadius / 2 // minDotSize = 50
				// let subtractingNumber = dotSize * 0.01

				let isLeader = true
				if (this.shapes[this.anim.leader] && this.shapes[this.anim.leader].inactive){

				} else if (this.anim.leader && this.anim.leader !== shape.id && this.anim.shapes[this.anim.leader] && this.anim.shapes[this.anim.leader].hasOwnProperty('delay')){
					theLeader = this.anim.shapes[this.anim.leader]
					isLeader = false
				}



				if (highlight || this.pulsateDots && isLeader){

					this.anim.leader = shape.id

					if (!this.anim.shapes[shape.id]) this.anim.shapes[shape.id] = {
						enabled:true,
						delay:100,
						size:1,
						reverse:false
					}


					let theShape = this.anim.shapes[shape.id]

					if (theShape.enabled){

						let amount = this.anim.amount
						// if (this.anim.speed === 'fast') amount = 0.02
						// if (this.anim.speed === 'slow') amount = 0.005

						if (theShape.size < 1) theShape.size = 1

						if (theShape.reverse){
							if ( theShape.size > 0) theShape.size += -1*amount
							else theShape.reverse = !theShape.reverse
						}

						if (!theShape.reverse) {
							if (theShape.size < 5) theShape.size += amount
							else theShape.reverse = !theShape.reverse
						}

						ctx.strokeStyle = `rgba(255,255,255,1)`
						ctx.fillStyle = color

						if (theShape.delay < 0) return theShape.enabled = false
						theShape.delay = theShape.delay - (this.anim.amount * 1)

					} else {

						ctx.strokeStyle = `rgba(255,255,255,1)`
						ctx.fillStyle = color

						if (theShape.delay > 99) return theShape.enabled = true
						theShape.delay = theShape.delay + (this.anim.amount * 1.8)

					}
				}

				let delay = this.anim.shapes[this.anim.leader] && this.anim.shapes[this.anim.leader].delay || 100
				// if (delay > 101) delay = 100
				dotSize = Math.abs(dotSize * ( delay / 100)) //
				ctx.arc(shape.cX, shape.cY, dotSize, 0, Math.PI * 2, true);

				ctx.fill()
				ctx.stroke()
				ctx.closePath()
			}

		},
		highlightLot(shape) {
			let { x, y, width, height } = shape

			x = this.p2({ x })
			y = this.p2({ y })
			width = this.p2({ x: width })
			height = this.p2({ y: height })

			this.ctx.beginPath()
			this.ctx.rect(x, y, width, height)
			this.ctx.stroke()
		},
		getArrayBuffer(cv) {
			return new Promise((resolve, reject) => {
				cv.toBlob(blob => {

					const reader = new FileReader();
					reader.addEventListener('loadend', () => {
						const arrayBuffer = reader.result;
						return resolve(arrayBuffer)
							//   bufferByteLen.textContent = arrayBuffer.byteLength + ' bytes.';

						//   // Dispay Blob content in an Image.
						//   const blob = new Blob([arrayBuffer], {type: mimeType});
						//   imageOut.src = URL.createObjectURL(blob);
					});

					reader.readAsArrayBuffer(blob);

				}, 'image/jpeg', 1.0)
			})
		},
		doFilterBeds(e){
			console.log('e', e.target.selectedOptions[0].text)
			console.log('Filtering Beds...', e.target.value)
			this.filterBeds = e.target.value
			let filterBedsValue = document.querySelector('[data-bed-filter-value]')
			console.log('Filter Beds Value', filterBedsValue)
			filterBedsValue.innerHTML = e.target.selectedOptions[0].text
			this.doFilters()
		},
		doFilterDen(e){
			console.log('e', e.target.selectedOptions[0].text)
			console.log('Filtering Den...', e.target.value)
			this.filterDen = e.target.value
			let filterDenValue = document.querySelector('[data-den-filter-value]')
			console.log('Filter Beds Value', filterDenValue)
			let text = e.target.selectedOptions[0].text
			text = text.charAt(0).toUpperCase() + text.slice(1)
			filterDenValue.innerHTML = text
			this.doFilters()
		},
		doFilterBaths(e){
			console.log('e', e.target.selectedOptions[0].text)
			console.log('Filtering Baths...', e.target.value)
			this.filterBaths = e.target.value
			let filterBathsValue = document.querySelector('[data-bath-filter-value]')
			console.log('Filter Baths Value', filterBathsValue)
			filterBathsValue.innerHTML = e.target.selectedOptions[0].text
			this.doFilters()
		},
		doFilterSqft(e){
			console.log('Filtering Sqft...', e.target.value)
			this.filterSqft = e.target.value
			let filterSqftValue = document.querySelector('[data-sqft-filter-value]')
			console.log('Filter Sqft Value', filterSqftValue)
			filterSqftValue.innerHTML = e.target.selectedOptions[0].text
			this.doFilters()
		},
		doFilterExposure(e){
			console.log('Filtering Exposure...', e.target.value)
			this.filterExposure = e.target.value
			let filterExposureValue = document.querySelector('[data-exposure-filter-value]')
			console.log('Filter Exposure Value', filterExposureValue)
			filterExposureValue.innerHTML = e.target.selectedOptions[0].text
			this.doFilters()
		},
		doFilters(){
			console.log('RUNNING DO FILTERS...', this.filterMaxPrice)
			let shapes = Object.values(this.shapes)

			this.filteredShapes = shapes.map(shape => {
				shape.hidden = false
				// if (shape.lot.units && shape.lot.units.length && !shape.lot.units[0].package) shape.hidden = true


				if ((shape.lot.units && shape.lot.units.length && shape.lot.units[0].package) && (parseInt(shape.lot.units[0].package.price) < this.filterMinPrice || parseInt(shape.lot.units[0].package.price) > this.filterMaxPrice)) shape.hidden = true
				if (this.filterBeds != 'all'){
					if (shape.lot.units && shape.lot.units.length && shape.lot.units[0].package && shape.lot.units[0].package.beds != this.filterBeds) shape.hidden = true
				}
				if (this.filterDen != 'all'){
					if (shape.lot.units && shape.lot.units.length && shape.lot.units[0].package && shape.lot.units[0].package.hasDen == false && this.filterDen == 'yes'){
						shape.hidden = true
					} else if (shape.lot.units && shape.lot.units.length && shape.lot.units[0].package && shape.lot.units[0].package.hasDen == true && this.filterDen == 'no'){
						shape.hidden = true
					}
				}
				if (this.filterBaths != 'all'){
					if (shape.lot.units && shape.lot.units.length && shape.lot.units[0].package && shape.lot.units[0].package.baths != this.filterBaths) shape.hidden = true
				}
				if (this.filterSqft != 'all'){
					if (shape.lot.units && shape.lot.units.length && shape.lot.units[0].package && shape.lot.units[0].package.sqft < this.filterSqft) shape.hidden = true
				}
				if (this.filterExposure != 'all'){
					if (shape.lot.units && shape.lot.units.length && shape.lot.units[0].package && shape.lot.units[0].package.exposure && shape.lot.units[0].package.exposure.toLowerCase() != this.filterExposure) shape.hidden = true
				}
				if (shape.lot && shape.lot.type === 'info') return shape
				return shape
			})
		},
		async doFloors(){
			let floorFilterExpanded = document.querySelector('[data-floor-filter-expanded]')

			let html = ``
			if(!this.unitGroups) {
				return html;
			}
			let unitGroups = Object.values(this.unitGroups);
			for (let i = 0; i < unitGroups.length; i++){
				html += `
					<div data-floor data-floor-id="${unitGroups[i].id}" class="floor-card ${i == 0 ? 'active' : ''}" style="line-height: 1.5; font-size: .75rem; text-align: center; padding: 0.25em 0.75em; border-radius: 4px;">
						<div>${unitGroups[i].name}</div>
						<div style="color: #9EA0A5;">${unitGroups[i].siteplan.lots.filter(x => x.type == 'lot').length} Unit(s)</div>
					</div>
				`
			}

			floorFilterExpanded.innerHTML = html
		},
		async changeFloor(id){
			console.log('Changing floor...', id)
			this.nowShowing = id
			// this.doFloors()
			this.initCanvas()
			await this.initSiteplan()
			let floors = document.querySelectorAll('[data-floor]')
			floors.forEach(floor => {
				floor.classList.remove('active')
				if (floor.getAttribute('data-floor-id') == id) floor.classList.add('active')
			})

		},
		getOrientation(w,h){
			return w > h ? 'l' : w === h ? 's' : 'p'
		},
		renderFilters(){
			this.resized()
			this.allInitialized = true

		},
		async doLots(siteplan){
			console.log('DO LOTS ********')
			this.shapes = {}
			let lots = {}

			this.lotInstanceObj = {}

			if (siteplan.lots && siteplan.lots.length){
				// console.log('LOTTTTTTTTTT', this.plans.masterplan.lots)

				siteplan.lots = siteplan.lots.map(lot => {
					if (this.updatedLots[lot.id]) {
						console.log(`this lot has been updated ${lot.name}`)
						lot = Object.assign(lot, this.updatedLots[lot.id])
						// lot = JSON.parse(JSON.stringify(this.updatedLots[lot.id]))
					}
					if (lot.tags && lot.tags.length){

						lot.tags = lot.tags.map(x => {

							if (x.id) return x.id
							return x
						})
					}

					return lot

				}).filter(lot => {
					// console.log('INITIAL LOT TWO', lot.name, lot)
					let pass = true
					if (lot.type === 'lot' && !this.showLots) return false
					if (lot.type === 'info' && !this.showAmenity) return false

					if (lot.type === 'lot' && !this.static && !this.tableMode) {
						pass = Boolean(lot.units && lot.units.length)
					}
					// console.log('INITIAL LOT TWO PASS RESULT', pass)
					return pass
				})


				siteplan.lots.forEach(lot => {
					// console.log('LOT FROM SITEPLAN LOTS', lot.name)
					// console.log('SHAPE ID', lot.shapeId)
					if (lot.siteplan && !this.socketIds[lot.siteplan]){
						this.socketIds[lot.siteplan] = 1
					}
					this.lotInstanceObj[lot.siteplan] = 1
					lot.units = lot.units.map(unitId => {

						let foundUnit = typeof unitId === 'string' ? this.units[unitId] : unitId
						if (foundUnit && foundUnit.unitGroup) {
							if (typeof foundUnit.unitGroup === 'string' && this.unitGroups[foundUnit.unitGroup]) foundUnit.unitGroup = this.unitGroups[foundUnit.unitGroup]
							return foundUnit
						}

						return {}
					}).filter(u => u.id)

					lots[lot.shapeId] = lot
				})

			}

			if (siteplan.shapes && siteplan.shapes.length){
				siteplan.shapes.forEach(shape => {
					shape.inactive = false
					if (lots[shape.id]) {

						shape.lot = lots[shape.id]
						let lot = lots[shape.id]
						if (lot.type === 'lot' && this.showTags && this.showTags.length){
							let pass = false
							this.showTags.forEach(tagId => {
								console.log('TAGGGGGG', lot.name, lot.tags, tagId, lot.tags.includes(tagId))
								if (lot.tags.includes(tagId)){
									pass = true
								}
							})
							if (!pass)shape.inactive = true

						}
						if (shape.lot.status === 'hold' && this.hideHold) {
							console.log('SETTING INACTIVE', shape.lot.id)
							shape.inactive = true
						}
					}
					if (shape.lot)
						this.shapes[shape.id] = shape
				})
			}

			this.renderFilters()

		},
		doDimensions(){
			let parentElement = this.canvas.parentNode
			this.parentElement = parentElement
			let pWIDTH = parentElement.clientWidth
			let pHEIGHT = parentElement.clientHeight
			console.log('pWIDTH', pWIDTH)
			console.log('pHEIGHT', pHEIGHT)
			let aspectRatio,imageWidth,imageHeight,moveX = 0,moveY = 0
			let oldWIDTH = this.pWIDTH
			let oldHEIGHT = this.pHEIGHT

			if (this.fitSectionDOM){
				pHEIGHT = this.sectionDOM.clientHeight
			}

			aspectRatio = this.height / this.width
			imageWidth = pWIDTH
			imageHeight = pWIDTH * aspectRatio
			moveY = (pHEIGHT - imageHeight) / 2

			this.pWIDTH = pWIDTH
			this.pHEIGHT = pHEIGHT


			if (imageHeight > pHEIGHT){
				aspectRatio = this.width / this.height
				imageHeight = pHEIGHT
				imageWidth = pHEIGHT  * aspectRatio
				moveX = (pWIDTH - imageWidth) / 2
				moveY = 0
			}

			this.imageWidth = imageWidth
			this.imageHeight = imageHeight
			this.moveY = moveY
			this.moveX = moveX

			this.camera =  {...this.camera, x: this.pWIDTH/2, y: this.pHEIGHT/2,zoom:1, }
			this.originalCamera =  {x: this.pWIDTH/2, y: this.pHEIGHT/2}

			this.boundaries.top = this.camera.y - this.moveY
			this.boundaries.bottom = this.camera.y + this.moveY
			this.boundaries.left = this.camera.x - this.moveX
			this.boundaries.right = this.camera.x + this.moveX
		},
		layoutShapes(){
			Object.values(this.shapes).forEach(shape => {
				this.reCoordinate(shape)
				this.doPoints(shape)
			})
		},
		getThumbUrl(url,pre = 'thumbnail'){
			console.log('URL', url)
			let imgSplit = url && url.split('/');
			if(!imgSplit) {
				return '';
			}
			let error = false
			let string = imgSplit.map( (x,xI) => {

				let isLast = xI === imgSplit.length-1
				if (!isLast) return x

				let xLast = x.length
				if (x.indexOf('.jpg') + 4 === xLast || x.indexOf('.jpeg') + 5 === xLast || x.indexOf('.png') + 4 === xLast){
					return `${pre}_${x}`
				} else {
					error = true
					return ''
				}

			}).join('/')

			return error ? '' : string
		},

		async initSiteplan(){
			console.trace('INIT')
			let siteplan = this.plans[this.nowShowing] || {};
			this.doLots(siteplan)

			this.siteplan = siteplan
			drawLot.siteplanImage.onload = () => {
				console.log('Start ran')
				this.start()
			}

			this.width = siteplan.width
			this.height = siteplan.height
			this.doDimensions()
			this.layoutShapes()
			this.doFilters()

			let siteplanBaseImage = siteplan.image

			this.thumbURL = this.getThumbUrl(siteplan.image,'large')

			if ( (this.tableMode || this.touchscreen) && (siteplan.options && siteplan.options.images && siteplan.options.images.hq && siteplan.options.images.hq.includes('://')) ){

				siteplanBaseImage = siteplan.options.images.hq

			}
			else if (this.thumbURL && this.thumbURL.length > 10 && !this.tableMode && !this.touchscreen){
				try{

					this.isThumb = await this.isImage(this.thumbURL)
					console.log('IS THUMB?', this.isThumb)
					if (this.isThumb){

						console.log('yes its a valid thumbnail ', this.thumbURL)
						siteplanBaseImage = this.thumbURL
						let img = new Image()
						img.onload = e => {
							drawLot.siteplanImage.src = siteplan.image
						}
						console.log('THUMB IS =>', this.isThumb)
						img.src = siteplan.image
					}

				}catch(err){
					console.log('thumbnail not valid')
				}

			}

			drawLot.siteplanImage.src = siteplanBaseImage;
			this.siteplanLoaded()

		},
		siteplanLoaded(){
			this.sectionDOM.classList.remove("loading-sp")
			if(this.sectionDOM.querySelector('.loading-sp > .loader')){
				this.sectionDOM.querySelector('.loading-sp > .loader').remove();
			}
			if (this.initCallback){
				if (typeof this.initCallback === 'function') return this.initCallback(this.nowShowing, this)
                else if (typeof this.initCallback === 'string' && typeof window[this.initCallback] === 'function') window[this.initCallback](this.nowShowing)
			}
		},
		plotShape(shape) {

			let ctx = this.ctx

			if (!shape) return console.error('caannot find shape, might be deleted')

			ctx.beginPath();
			this.plotLines(shape)

		},
		initCanvas() {
			let canvas = this.sectionDOM.querySelector('canvas')
			canvas.style.display = ''

			if (!canvas) return console.error('ERRORRR')

			this.sectionDOM.classList.add(`siteplan-widget-style-${this.sectionStyle}`)

			let ctx = canvas.getContext('2d');
			this.ctx = ctx
			this.ctx.imageSmoothingEnabled = false;
			this.canvas = canvas
			return {canvas,ctx}


		},
		getEventLocation(e) {
			if (e.touches && e.touches.length <= 2){
				return { x:e.touches[0].clientX, y: e.touches[0].clientY }
			} else if (e.clientX && e.clientY){
				return { x: e.clientX, y: e.clientY }
			}
		},
		onPointerMove(e){

			e.preventDefault();
			e.stopPropagation();
			let { clientX, clientY} = e
			let self = this
			// if (Date.now() - this.touchStarted > 415) self.mouseOver = null
			self.mouseOver = null

			if (e.touches){

				// if (this.mobileMode && !this.touchscreen && e.touches.length !== 2 ) {
				// 	return e.stopPropagation()
				// }
				// if (e.touches.length === 2){
				// 	clientX = (e.touches[0].clientX + e.touches[1].clientX) /2
				// 	clientY = (e.touches[0].clientY + e.touches[1].clientY) /2
				// }

				// if (this.devDOM && e.touches.length === 2){
				// 	let touches = {
				// 		x1: e.touches[0].clientX,
				// 		y1:e.touches[0].clientY,
				// 		x2:e.touches[1].clientX,
				// 		y2:e.touches[1].clientY,
				// 	}
				// 	let iW = window.innerWidth
				// 	let iH = window.innerHeight
				// 	let hPerc = (Math.abs(touches.x1 - touches.x2) / iW) * 100
				// 	let vPerc = (Math.abs(touches.y1 - touches.y2) / iH) * 100
				// 	this.developerInfo = {
				// 		horizontal:hPerc,
				// 		vertical:vPerc,

				// 	}
				// 	this.updateDev()
				// }

				clientX = e.touches[0].clientX /2
				clientY = e.touches[0].clientY /2

				if (this.devDOM && e.touches.length === 1){
					let touches = {
						x1: e.touches[0].clientX,
						y1:e.touches[0].clientY
					}
					let iW = window.innerWidth
					let iH = window.innerHeight
					let hPerc = (touches.x1 / iW) * 100
					let vPerc = (touches.y1 / iH) * 100
					this.developerInfo = {
						horizontal:hPerc,
						vertical:vPerc,

					}
					this.updateDev()
				}

			}


			if (self.isMouseDown){
				self.isMousePan = true
				self.performedAction = true
			}


			// if (self.isMouseDown && self.camera.zoom !== 1){
			if (self.isMouseDown){
					self.pan.lastX = self.camera.x
					self.pan.lastY = self.camera.y

					let cX = self.getEventLocation(e).x/self.camera.zoom
					let cY = self.getEventLocation(e).y/self.camera.zoom

					self.camera.x = cX - self.dragStart.x
					self.camera.y = cY - self.dragStart.y

					if (this.devDOM){
						this.developerInfo['CMX'] = `${self.camera.x.toFixed(2)}, CMY:${self.camera.y.toFixed(2)}; MOUSE PAN? ${this.isMousePan}`
						this.updateDev()
					}

					if (self.updated.extendedHeight){

					} else{

						// if (self.camera.y <= self.boundaries.top){
						// 	self.camera.y = self.boundaries.top
						// 	self.dragStart.y = cY - self.camera.y
						// }
						// if (self.camera.y >= self.boundaries.bottom){
						// 	self.camera.y = self.boundaries.bottom
						// 	self.dragStart.y = cY - self.camera.y
						// }

					}

					if (self.updated.extendedWidth ){

					} else {

						// if (self.camera.x <= self.boundaries.left || self.camera.zoom === 1){

						// 	self.camera.x = self.boundaries.left
						// 	self.dragStart.x = cX - self.camera.x

						// }
						// if (self.camera.x >= self.boundaries.right || self.camera.zoom === 1){

						// 	self.camera.x = self.boundaries.right
						// 	self.dragStart.x = cX - self.camera.x

						// }
					}
			}else {
				self.isMousePan = false
				if (!this.touchscreen && !this.tableMode){
					self.virtualElement.getBoundingClientRect = self.generateGetBoundingClientRect(clientX, clientY - 20);
					self.popperInstance.update();
				}
			}



			var rect = e.target.getBoundingClientRect(),
				x = e.clientX - rect.left,
				y = e.clientY - rect.top,
				i = 0, r;

			self.mouseX = x
			self.mouseY = y

		},
		onPointerDown(e){
			let self = this

			this.touchInit = 0
			this.touchStarted = Date.now()
			var rect = e.target.getBoundingClientRect(),
				x = e.clientX - rect.left,
				y = e.clientY - rect.top,
				i = 0, r;
			self.mouseX = x
			self.mouseY = y

			if (e.touches){
				this.developerInfo['pointid'] = e.pointerId
				this.updateDev()
			}

			self.isMousePan = false
			self.mouseDownShape = self.mouseOver
			self.isMouseDown = true
			self.isMouseUp = false
			console.log('handle pinter DOWN', e.touches)
			self.dragStart.x = self.getEventLocation(e).x/self.camera.zoom - self.camera.x
			self.dragStart.y = self.getEventLocation(e).y/self.camera.zoom - self.camera.y

		},
		onPointerUp(e){
			let self = this
			this.touchEnded = Date.now()
			this.touchDuration = this.touchEnded - this.touchStarted
			console.log('POINTER UP DURATION',this.touchDuration)
			console.log('Performed Action', this.performedAction)
			if (this.devDOM){
				this.developerInfo['PointerUp'] = true
				if (e.touches){
					this.developerInfo['pointid'] = e.pointerId
				}
				this.updateDev()
			}

			self.isMouseUp = true
			if ((!self.isMousePan || this.touchDuration < 415) && !self.performedAction){

				if (self.mouseOver && self.mouseOver.lot && !self.static){
					if (self.mouseOver.lot.type === 'lot' && self.mouseOver.lot.units && self.mouseOver.lot.units.length ){
						self.showLotInfo(self.mouseOver.lot)
					} else if  (self.mouseOver.lot.type === 'info') {
						self.showAmenityInfo(self.mouseOver.lot)
					}
				} else {
					this.onEvent('TAP')
				}
			}
			self.isMousePan = false
			self.isMouseDown = false
			self.mouseOver = null
			self.mouseX = null
			self.mouseY = null
			self.performedAction = false



		},
		async showAmenityInfo(lot){
			console.log('Showing amneity info', lot)
			let self = this
		},
		async showLotInfo(lot){
			console.log('Showing lot info...', lot)
			let self = this
			if (lot.units && lot.units.length && lot.units[0].unitNumber){
				this.onEvent('SHOW_LOT',lot)
			}

		},
		handleTouch(e, singleTouchHandler){
			if ( !e.touches || e.touches.length == 1 ) {
				if (!e.hasOwnProperty('clientX')){
					if (e.touches){
						e.clientX = e.touches[0].clientX
						e.clientY = e.touches[0].clientY
					}
				}
				singleTouchHandler(e)
			} else if (e.type == "touchmove" && e.touches.length == 2) {
				isDragging = false
				this.handlePinch(e)
			}
		},
		handlePinch(e){
			e.preventDefault()

			let touch1 = { x: e.touches[0].clientX, y: e.touches[0].clientY }
			let touch2 = { x: e.touches[1].clientX, y: e.touches[1].clientY }

			// This is distance squared, but no need for an expensive sqrt as it's only used in ratio
			let currentDistance = (touch1.x - touch2.x)**2 + (touch1.y - touch2.y)**2

			if (this.initialPinchDistance == null){
				this.initialPinchDistance = currentDistance
			} else {
				this.adjustZoom( null, currentDistance/this.initialPinchDistance )
				this.initialPinchDistance = currentDistance
			}
		},
		adjustZoom(zoomAmount, zoomFactor){
			console.log('ZOOM AMOUNT', zoomAmount)
			console.log('ZOOM FACTOR', zoomFactor)
			let self = this
			if (self.zooming && !self.mobileMode) return console.error('still zooming')


			if (!this.isMousePan){

				if (zoomAmount){
					let zoom = Math.min(self.camera.zoom + zoomAmount, self.MAX_ZOOM)
					zoom = Math.max(zoom, self.MIN_ZOOM)
					self.camera.nextZoom = zoom
				} else if (zoomFactor){
					console.log(zoomFactor)
					let zoom = Math.min(zoomFactor*self.camera.zoom, self.MAX_ZOOM)
					zoom = Math.max(zoom, self.MIN_ZOOM)
					self.camera.nextZoom = zoom
					self.performedAction = true
				}

				// self.camera.nextZoom = Math.min( self.camera.nextZoom, self.MAX_ZOOM )
				// self.camera.nextZoom = Math.max( self.camera.nextZoom, self.MIN_ZOOM )


			}

		},
		zoomReset(){

			let self = this
			if (!self.zooming) {
				console.log('RESETTTTINGGGGGGGG')
				this.developerInfo['RESET'] = 'true'
				this.updateDev()
				self.camera = {
					...self.camera,
					x:self.originalCamera.x,
					y:self.originalCamera.y,
					zoom:1,
					nextZoom:1,
				}

			}
		},
		zoomIn(){
			delete this.developerInfo['RESET']
			this.updateDev()
			let self = this
			if (!self.zooming) self.adjustZoom(0.4)
		},
		zoomOut(){
			delete this.developerInfo['RESET']
			this.updateDev()
			let self = this
			if (!self.zooming) self.adjustZoom(-0.4)
		},
		zoomWheel(amount){
			let self = this
			self.adjustZoom(amount)
		},
		resized(e){
			console.log('RESETTINGGGGG (resized fn)')
			let self = this
			delete this.developerInfo['RESET']
			this.developerInfo = {'RESIZED':true}

			self.dotRadius = Infinity
			if (self.canvas){
				if (window.innerWidth < 768){
					this.mobileMode = true
				} else {
					this.mobileMode = false
				}
				console.log('MOVILE MODE', this.mobileMode)
				self.doDimensions()
				self.layoutShapes()

				if (window.innerWidth < 500 ){
				} else if (!this.zooming && this.allInitialized){
					self.zoomReset()
				}
				self.doFilters()

			}

		},
		updateDev(){
			if (!this.devDOM) return
			let html = ''
			Object.entries(this.developerInfo).forEach( ([key,value]) => {
				html += `<span>${key}: ${value}</span> | `
			})
			this.devDOM.innerHTML = html
		},
		initListeners(){
			let canvas = this.canvas

			canvas.style.touchAction="none"
			// this.sectionDOM.style.touchAction="none"

			this.sectionDOM.addEventListener('contextmenu', e => {
				e.preventDefault();
			})

			this.virtualElement = {
				getBoundingClientRect: this.generateGetBoundingClientRect(),
			}


			const tooltip = document.querySelector('#siteplan-lot-tooltip');
			// this.popperInstance = Popper.createPopper(this.virtualElement, tooltip);
			this.popperInstance = createPopper(this.virtualElement, tooltip);

			let pointerDown = 'ontouchstart' in document ? 'touchstart' : 'onpointerdown' in document ? 'pointerdown' : 'mousedown';
			let pointerUp = 'ontouchend' in document ? 'touchend' : 'onpointerup' in document ? 'pointerup' :  'mouseup';
			let pointerMove = 'ontouchmove' in document ? 'touchmove' : 'onpointermove' in document ? 'pointermove' :  'mousemove';
			let pointerCancel = 'ontouchcancel' in document ? 'touchcancel' : 'pointercancel';

			console.log('OPINTER DOWN', pointerDown)
			console.log('OPINTER move', pointerMove)

			canvas.addEventListener('mouseout', e => {
			})
			canvas.addEventListener('mouseenter', e => {
			})

			canvas.addEventListener(pointerDown, (e) => this.handleTouch(e, this.onPointerDown.bind(this)))
			canvas.addEventListener(pointerUp,  (e) => {
				console.log('Pointer up')
				this.initialPinchDistance = null
				this.sectionDOM.classList.remove('one-touch')
				if (!e.touches || e.touches.length <= 1) {
					this.onPointerUp(e)
				}
			})
			canvas.addEventListener(pointerCancel,  (e) => {
				console.log('%c POINTER CANCELLED', 'color:red')
			})
			canvas.addEventListener(pointerMove,  (e) => {

				this.isMousePan = true

				if (e.touches && e.touches.length == 1 && this.mobileMode && !this.touchscreen) {
					// this.sectionDOM.classList.add('one-touch')
					this.developerInfo['Touch'] = 'One F'
					this.onPointerMove(e)
					this.updateDev()

				} else if (e.touches && e.touches.length == 2 && this.mobileMode && !this.touchscreen) {
					this.isMousePan = false
					this.performedAction = true
					this.handlePinch(e)
				} else {
					// this.sectionDOM.classList.remove('one-touch')
					this.onPointerMove(e)

				}
			})

			window.addEventListener('resize', e => this.debounceFunction(e,this.resized.bind(this),1000))

			if (this.mouseWheelZoom){
				canvas.addEventListener( 'wheel', (e) => this.zoomWheel(e.deltaY*this.SCROLL_SENSITIVITY))
			}

			let zoomContainer = this.parentElement.querySelector('[data-s-tool-container]')
			let floorFilter = this.parentElement.querySelector('[data-floor-filter-dropdown]')
			let floorFilterExpanded = this.parentElement.querySelector('[data-floor-filter-expanded]')
			let floorCards = this.parentElement.querySelectorAll('[data-floor]')

			let filterClear = document.querySelector('[data-clear-button]')

			let filterMinPrice = document.querySelector('[data-price-filter-lower]')
			let filterMaxPrice = document.querySelector('[data-price-filter-upper]')
			let filterBeds = document.querySelector('[data-bed-filter]')
			let filterDen = document.querySelector('[data-den-filter]')
			let filterBaths = document.querySelector('[data-bath-filter]')
			let filterSqft = document.querySelector('[data-sqft-filter]')
			let filterExposure = document.querySelector('[data-exposure-filter]')

			let filterMinPriceValue = document.querySelector('[data-price-filter-lower-value]')
			let filterMaxPriceValue = document.querySelector('[data-price-filter-upper-value]')
			let filterBedsValue = document.querySelector('[data-bed-filter-value]')
			let filterDenValue = document.querySelector('[data-den-filter-value]')
			let filterBathsValue = document.querySelector('[data-bath-filter-value]')
			let filterSqftValue = document.querySelector('[data-sqft-filter-value]')
			let filterExposureValue = document.querySelector('[data-exposure-filter-value]')

			let detailPage = document.querySelector('[data-detail-page]')
			let detailOverlay = document.querySelector('[data-detail-overlay]')
			let detailClose = document.querySelector('[data-detail-close]')

			if (detailOverlay){
				detailOverlay.addEventListener('click', e => {
					detailPage.classList.remove('isActive')
					detailPage.classList.add('notActive')
				})
			}
			if (detailClose){
				detailClose.addEventListener('click', e => {
					detailPage.classList.remove('isActive')
					detailPage.classList.add('notActive')
				})
			}


			if (filterClear){
				filterClear.addEventListener('click', e => {
					console.log('Clearing filters...')

					//reset price
					filterMaxPrice.value = filterMaxPrice.max
					filterMinPrice.value = 0
					filterMaxPriceValue.innerHTML = '$' + filterMaxPrice.max
					filterMinPriceValue.innerHTML = '$0'

					//reset bed, sqft, exposure
					filterBeds.value = 'all'
					filterBedsValue.innerHTML = 'All'
					filterDen.value = 'all'
					filterDenValue.innerHTML = 'All'
					filterBaths.value = 'all'
					filterBathsValue.innerHTML = 'All'
					filterSqft.value = 'all'
					filterSqftValue.innerHTML = 'All'
					filterExposure.value = 'all'
					filterExposureValue.innerHTML = 'All'

					this.updatePrice('min',0)
					this.updatePrice('max',filterMaxPrice.max)
					this.filterBeds = 'all'
					this.filterDen = 'all'
					this.filterBaths = 'all'
					this.filterSqft = 'all'
					this.filterExposure = 'all'

					this.doFilters()
				})
			}
			if (filterMinPrice){
				var lowerVal = parseInt(filterMinPrice.value);
				let self = this
				filterMinPrice.oninput = function () {
					if (drawLot.timerId) clearTimeout(drawLot.timerId)

					lowerVal = parseInt(filterMinPrice.value);
					upperVal = parseInt(filterMaxPrice.value);

					if (lowerVal > upperVal - 1) {
						filterMaxPrice.value = lowerVal + 1;
						filterMaxPriceValue.innerHTML='$' + filterMaxPrice.value
						if (upperVal == filterMaxPrice.max) {
							filterMinPrice.value = parseInt(filterMaxPrice.max) - 1;
						}
					}
					filterMinPriceValue.innerHTML='$' + this.value

					drawLot.timerId = setTimeout(function () {
						self.updatePrice('min',filterMinPrice.value)
						self.updatePrice('max',filterMaxPrice.value)
						self.doFilters()
						drawLot.timerId = undefined;
					}, 400)
				};
			}
			if (filterMaxPrice){
				var upperVal = parseInt(filterMaxPrice.value);
				let self = this
				filterMaxPrice.oninput = function () {
					if (drawLot.timerId) clearTimeout(drawLot.timerId)

					lowerVal = parseInt(filterMinPrice.value);
					upperVal = parseInt(filterMaxPrice.value);

					if (upperVal < lowerVal + 1) {
						filterMinPrice.value = upperVal - 1;
						filterMinPriceValue.innerHTML='$' + filterMinPrice.value
						if (lowerVal == filterMaxPrice.min) {
							filterMaxPrice.value = 1;
						}
					}
					filterMaxPriceValue.innerHTML='$' + this.value

					drawLot.timerId = setTimeout(function () {
						self.updatePrice('min',filterMinPrice.value)
						self.updatePrice('max',filterMaxPrice.value)
						self.doFilters()
						drawLot.timerId = undefined;
					}, 400)
				};
			}
			if (filterBeds){
				filterBeds.addEventListener('change', e => this.doFilterBeds(e))
			}
			if (filterDen){
				filterDen.addEventListener('change', e => this.doFilterDen(e))
			}
			if (filterBaths){
				filterBaths.addEventListener('change', e => this.doFilterBaths(e))
			}
			if (filterSqft){
				filterSqft.addEventListener('change', e => this.doFilterSqft(e))
			}
			if (filterExposure){
				filterExposure.addEventListener('change', e => this.doFilterExposure(e))
			}


			if (zoomContainer){
				let zoomIn = zoomContainer.querySelector('[data-s-tool=in]')
					,zoomReset = zoomContainer.querySelector('[data-s-tool=reset]')
					,zoomOut = zoomContainer.querySelector('[data-s-tool=out]')


				if (zoomIn) {
					zoomIn.addEventListener(pointerUp,  (e) => { if (!e.touches || e.touches.length <= 1) this.zoomIn(e) })
				}
				if (zoomReset) {
					zoomReset.addEventListener(pointerUp,  (e) => { if (!e.touches || e.touches.length <= 1) this.zoomReset(e) })
				}

				if (zoomOut) {
					zoomOut.addEventListener(pointerUp,  (e) => { if (!e.touches || e.touches.length <= 1) this.zoomOut(e) })
				}

			}

			if (floorFilter){
				floorFilter.addEventListener(pointerUp,  (e) => {
					if (!e.touches || e.touches.length <= 1){
						if (floorFilterExpanded.style.display == 'none'){
							floorFilterExpanded.style.display = 'grid'
						} else{
							floorFilterExpanded.style.display = 'none'
						}
					}
				})
			}
			floorCards.forEach(dom => {
				dom.addEventListener(pointerUp,  (e) => {
					if (!e.touches || e.touches.length <= 1){
						this.changeFloor(dom.dataset.floorId)
					}
				})
			})


		},
		updatePrice(type,price){
			console.log('Updating price...', price)
			if (type == 'min') this.filterMinPrice = price;
			if (type == 'max') this.filterMaxPrice = price;
		},
		generateGetBoundingClientRect(x = 0, y = 0) {
			return () => ({
				width: 0,
				height: 0,
				top: y,
				right: x,
				bottom: y,
				left: x,
			});
		},
		recalc(){

			let iw = this.imageWidth * this.camera.zoom
			let ih = this.imageHeight * this.camera.zoom
			let it = ih
			let ib = ih
			let extendedWidth = iw > this.pWIDTH
			let extendedHeight = ih > this.pHEIGHT



			this.updated = {
				it,
				ib,
				iw,
				ih,
				extendedWidth,
				extendedHeight
			}
		},
		drawShapes(){
			if (!this.allInitialized) return

			let self = this
			let shapes = this.filteredShapes
			let canvas = self.canvas
			let ctx = self.ctx
			if (!shapes && !shapes.length) return console.error('something happened')
			canvas.width = this.pWIDTH
			canvas.height = this.pHEIGHT


			// Translate to the canvas centre before zooming - so you'll always zoom on what you're looking directly at
			this.zooming = false

			if (this.camera.nextZoom !== this.camera.zoom) this.zooming = true
			if (this.camera.nextZoom > this.camera.zoom){
				console.log('zooming In ', this.camera.zoom)
				if (this.camera.nextZoom - this.camera.zoom < 0.2) this.camera.zoom = this.camera.nextZoom
				else {
					this.camera.zoom += .15
				}
			} else if (this.camera.nextZoom < this.camera.zoom){

				if (this.camera.zoom - this.camera.nextZoom < 0.2) this.camera.zoom = this.camera.nextZoom
				else {
					this.camera.zoom -= .15
				}
			}

			ctx.translate( this.pWIDTH / 2, this.pHEIGHT / 2 )
			ctx.scale(self.camera.zoom, self.camera.zoom)
			ctx.translate( -this.pWIDTH * 1 + self.camera.x, -this.pHEIGHT * 1 + self.camera.y )
			ctx.clearRect(0, 0, this.pWIDTH, this.pHEIGHT);


			self.ctx.drawImage(self.siteplanImage,this.moveX,this.moveY,self.imageWidth,self.imageHeight)

			Object.values(shapes).forEach(shape => {
				if (shape.lot && shape.lot.id){
					if (self.updatedLots[shape.lot.id]){
						let {name,status,attachedUnit,brick,inherit} = self.updatedLots[shape.lot.id]
						shape.lot = {
							...shape.lot,
							name,status,attachedUnit,brick,inherit
						}
					}
				}
				this.plotShape(shape)
			})


			if (document.querySelector('#siteplan-lot-tooltip') && !this.mobileMode && !this.touchscreen && !this.tableMode){
				let tt = document.querySelector('#siteplan-lot-tooltip')
				let text = document.querySelector('#siteplan-lot-tooltip span')
				let textContainer = document.querySelector('#siteplan-lot-tooltip div')
				if (this.mouseOver){
					tt.style.opacity = 1

					let label = 'No Unit'

					if (this.mouseOver.lot && this.mouseOver.lot.units && typeof this.mouseOver.lot.units != 'string' && this.mouseOver.lot.units.length){
						label = 'Unit ' + this.mouseOver.lot.units[0].unitNumber + ' - ' + this.mouseOver.lot.units[0].name
						textContainer.style.backgroundColor = this.colorLookup[this.mouseOver.lot.units[0].salesStatus] || 'rgba(255,255,255,0)'
						textContainer.style.color = '#FFFFFF'
					}
					if (this.mouseOver.lot.type === 'lot' && this.mouseOver.lot.units && typeof this.mouseOver.lot.units != 'string' && this.mouseOver.lot.units.length && this.mouseOver.lot.units[0].salesStatus != 'available') {
						// label = `${this.mouseOver.lot.units[0].salesStatus == 'notreleased' ? 'NOT RELEASED' : this.mouseOver.lot.units[0].salesStatus == 'soldConditional' || this.mouseOver.lot.units[0].salesStatus == 'soldConditional' ? 'CONDITIONAL' : this.mouseOver.lot.units[0].salesStatus.toUpperCase()}`
						label = `${this.statusNames[this.mouseOver.lot.units[0].salesStatus]}`
						textContainer.style.backgroundColor = this.colorLookup[this.mouseOver.lot.units[0].salesStatus] || 'rgba(255,255,255,0)'
						textContainer.style.color = '#FFFFFF'
					}
					if (this.mouseOver.lot.type === 'info'){
						label = `${this.mouseOver.lot.name}`
						textContainer.style.backgroundColor = 'rgba(255,255,255,1)'
                        textContainer.style.border = '2px solid #000000'
                        textContainer.style.color = '#000000'
					}
					text.innerHTML = label
				} else {
					this.canvas.style.cursor = 'inherit'
					tt.style.opacity = 0
				}
			}
		},
		start(){
			this.analyzeLots()

			const draw = () => {
				this.recalc()
				// drawLot.updateDOM()
				this.drawShapes()
				if (this.hasError) return
				requestAnimationFrame( draw )
			}
			if (!this.alreadyListening){
				draw()
				this.initListeners()
				this.initSocket()
				this.alreadyListening = true
			}

		},
		updateLot(e){
			this.updatedLots[e.id] = e

			let siteplan = this.plans[this.nowShowing] || {};
			this.doLots(siteplan)
		},
		initSocket(){
			console.log('STARTING SOCKET', this.isSocket)
			if (!this.isSocket || typeof io === 'undefined') return

			Object.keys(this.socketIds).forEach( key => {

				this.sockets[key] = io(`https://ws.bildhive.com`,{query:{id:key}});
				let socket = this.sockets[key]
				socket.on("connect", () => {
					console.log(`[${key}] is it discocnnected? => `,socket.disconnected); // false
				});
				socket.on("updateLot", (e) => {
					console.log(`[${key}] update LOT CALLED FROM SERVER? => `,e);
					this.updateLot(e)
				});
				socket.on("bh_refresh", (e) => {
					window.location.href = window.location.href
				});

				socket.on("connect_error", (error) => {
					// ...
					console.error(`[${key}] CONNECT ERRORRRR`, error)
				});
				socket.on("error", (error) => {
					console.error(`[${key}] ERRORRRR`, error)
				});
				console.log('SOCKET CONNECTEDDDDDDD ', key, ' => ', socket)
			})

		},
		addTime(addMins = 0){
			let add = addMins * 60000
			let time = Date.now() + add
			return new Date(time).getTime()
		},
		getSiteplan(){

			return new Promise( (resolve,reject) => {
				if (spData && spData.siteplan && spData.siteplan.id) return resolve(spData)
				console.log('SPDATA', spData)
				let url = this.spURL
				const getApi = (res) => {
					fetch(url)
						.then(res => res.json()).then(data => {
							let result = data;
							console.log('SPDATA DATA COMING BACK', data)
							if ($userType === 'agent') {
								result.units = data.units.filter(u => (u.salesStatus === 'available' && !u.allocatedTo) || ((u.allocatedTo && u.allocatedTo.id) === currentUser.id))
							}
							return res(result)
						}).catch(reject)
				}

				return getApi(resolve)

			})

		},
		formatDate(s){
			let numDate = new Date(s)
			let month = numDate.getMonth()+1
			// let day = numDate.getDate()
			let year = numDate.getFullYear()
			// let time = numDate.getTime()
			// let formatTime = this.moment(time).format('HH:mm')
			let finalCreatedDate = month+'/'+year
			return finalCreatedDate
		},
		async init({
			animAmount=0.5,
			pulsateDots = 0,
			hideAvail=0,
			hideColor,
			hideHold = false,
			openModel,
			showOnlyModel = 0,
			noPanZoomedOut = 0,
			touchscreen = false,
			fitSectionDOM = false,
			tableMode = false,socket = false,
			hideFilter = false,
			hideDots = false,
			hidePrices = true,
			cb,
			linkTemplate = '',
			sectionDOM = '',
			show='siteplan',
			tabs = {siteplan:'',masterplan:''},
			instance,
			showTags = [],
			devel = 0,
			filterStyle = 'bottom',
			style = 'one',
			popup = false,
			enableLink = true,
			initialZoom = 1,
			showPremiums = false,
			hideStatus = false
		}){
			console.log('CBBBB', cb)
			const getBool = val => {
				if (val === 'true') return true
				if (val === 'false') return false
				return typeof val === 'string' ? Boolean(+val) : Boolean(val)
			}

			if (initialZoom){
				this.initialZoom = initialZoom
				this.camera.nextZoom = initialZoom
			}


			this.openModel = openModel
			this.noPanZoomedOut = noPanZoomedOut
			this.showOnlyModel = showOnlyModel
			this.touchscreen = touchscreen
			this.fitSectionDOM = fitSectionDOM
			this.isSocket = socket
			this.hidePrices = hidePrices
			this.enableLink = getBool(enableLink) || getBool(tableMode),
			this.popup = popup
			this.sectionStyle = style
			this.filterStyle = filterStyle
			this.pulsateDots = getBool(pulsateDots)
			this.hideStatus = getBool(hideStatus)
			animAmount = +animAmount
			if (!isNaN(animAmount)) this.anim.amount = animAmount

			this.devel = location.href.includes('bhdeveloper=1') ? 1 : devel
			if (showTags && showTags.length){
				this.showTags = showTags
			}
			this.hideHold = getBool(hideHold)
			this.hideAvail = getBool(hideAvail),
			this.showPremiums = getBool(showPremiums)


			this.instance = instance
			this.tabs = tabs
			this.showing = show
			this.linkTemplate = linkTemplate
			this.initCallback = initCallback || cb
			this.onEvent = onEvent

			this.hideDots = getBool(hideDots),

			this.tableMode = getBool(tableMode)
			this.sectionDOM = dom
			this.hideHold = hideHold
			if (hideColor) this.hideColor = hideColor

			if (typeof dom === 'string'){
                this.sectionDOM = document.querySelector(dom)
                if (!dom) this.sectionDOM = document.querySelector('#'+dom)
                if (!dom) this.sectionDOM = document.querySelector('.'+dom)
            }

            if (!this.sectionDOM || !this.sectionDOM.tagName) return console.error('no dom found')

			if (tableMode){
				this.sectionDOM.classList.add('table-mode')
			}

			let url = `${this.apiURL}/v1/condogrid?token=${this.instance}`

			this.spURL = url


			await this.getSiteplan().then( async data => {

					console.log('DATA FROM GETSITEPLAN', data)
					this.units = {}
					data.units.filter(x => x.unitGroup && x.packages).map(x => {
						if (x.packages && x.packages.length && x.packages[0] != null){
							let exposure = x.packages[0].exposure && x.packages[0].exposure.toLowerCase()
							let occupancy = this.formatDate(x.packages[0].occupancy)
							x.packages[0].occupancy = occupancy
							x.packages[0].exposure = exposure && exposure[0] && (exposure[0].toUpperCase() + exposure.slice(1))
						}
						x.package = x.packages[0]
						return x
					}).forEach(x => {
						this.units[x.id] = x
					})

					if (!data.instances) data.instances = []

					if (data.condo && data.condo.floors && data.condo.floors.length){
						let uG = {}
						data.condo.floors.forEach( item => {
							uG[item.id] = item
						})
						this.unitGroups = uG
					}

					let priceFilterLower = document.querySelector('[data-price-filter-lower]')
					let priceFilterUpper = document.querySelector('[data-price-filter-upper]')
					let priceFilterLowerValue = document.querySelector('[data-price-filter-lower-value]')
					let priceFilterUpperValue = document.querySelector('[data-price-filter-upper-value]')
					let bedFilter = document.querySelector('[data-bed-filter]')
					let bathFilter = document.querySelector('[data-bath-filter]')
					let sqftFilter = document.querySelector('[data-sqft-filter]')
					let exposureFilter = document.querySelector('[data-exposure-filter]')

					let allBeds = []
					let allBaths = []
					let allSqft = []
					let allExposure = []
					let maxPrice = 1
					Object.values(this.units).forEach(x => {
						if (x.package){
							let sqftRange = parseInt(x.package.sqft/250)
							if (!allBeds.includes(x.package.beds)) allBeds.push(x.package.beds)
							if (!allBaths.includes(x.package.baths)) allBaths.push(x.package.baths)
							if (!allSqft.includes(250*sqftRange)) allSqft.push(250*sqftRange)
							if (!allExposure.includes(x.package.exposure && x.package.exposure.toLowerCase())) allExposure.push(x.package.exposure && x.package.exposure.toLowerCase())
							if (maxPrice < x.package.price) maxPrice = x.package.price
						}
					})
					allBeds.sort((a,b) => a-b)
					allBaths.sort((a,b) => a-b)
					allSqft.sort((a,b) => a-b)
					allExposure.sort((a,b) => a.localeCompare(b))

					if (priceFilterLower && priceFilterUpper && priceFilterLowerValue && priceFilterUpperValue){
						priceFilterLower.max = maxPrice-1
						priceFilterUpper.max = maxPrice
						priceFilterUpper.value = maxPrice
						priceFilterLowerValue.innerHTML = '$0'
						priceFilterUpperValue.innerHTML = '$' + maxPrice
						this.filterMaxPrice = maxPrice
					}

					if (bedFilter){
						allBeds.forEach(x => {
							let option = document.createElement('option')
							option.value = x
							option.innerHTML = x + ' Bed(s)'
							bedFilter.appendChild(option)
						})
					}

					if (bathFilter){
						allBaths.forEach(x => {
							let option = document.createElement('option')
							option.value = x
							option.innerHTML = x + ' Bath(s)'
							bathFilter.appendChild(option)
						})
					}

					if (sqftFilter){
						allSqft.forEach(x => {
							let option = document.createElement('option')
							option.value = x
							option.innerHTML = x + '+'
							sqftFilter.appendChild(option)
						})
					}

					if (exposureFilter){
						allExposure.forEach(x => {
							let option = document.createElement('option')
							option.value = x
							option.innerHTML = x.charAt(0).toUpperCase() + x.slice(1)
							exposureFilter.appendChild(option)
						})
					}

					this.plans = {}
					if (this.unitGroups) {
						Object.values(this.unitGroups).forEach(x => {
							this.plans[x.id] = x.siteplan
						})
					}

					this.nowShowing = Object.keys(this.plans)[0]

					console.log('UNITS', this.units)
					console.log('UNIT GROUPS', this.unitGroups)
					console.log('PLANS', this.plans)

				this.doFloors()
				await this.changeFloor(Object.keys(this.plans)[0])
				setTimeout(() => {
					this.changeFloor(Object.keys(this.plans)[0])
				},100)


				// this.initCanvas()
				// await this.initSiteplan()

			})


		}
	}


    if (options && Object.keys(options).length){
        drawLot.init({
            ...options
        })
    }
    return drawLot
}


export default siteplanApp
// window.siteplanApp = siteplanApp
